.flex {
  display: flex;
}
.flex-fs {
  display: flex;
  align-items: flex-start;

}
.flex-sb {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.flex-sb-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-sb-end {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.flex-sa {
  display: flex;
  justify-content: space-around;
}

.flex-e {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}
.flex-e-center {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.flex-e-end {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center-start {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-stretch {
  display: flex;
  align-items: stretch;
}
.inline-flex-start {
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.inline-flex-center {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.inline-flex-end {
  display: inline-flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.flex-align-stretch {
  align-items: stretch;
}

.flex-grow {
  flex-grow: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-col {
  flex-direction: column;
}

.align-self-center {
  align-self: center;
}

.flex-20 {
  width: 20%;
}
.flex-30 {
  width: 20%;
}
.flex-40 {
  width: 40%;
}
.flex-45 {
  width: 45%;
}
.flex-50 {
  width: 50%;
}
.flex-55 {
  width: 55%;
}
.flex-60 {
  width: 60%;
}
.flex-70 {
  width: 70%;
}
.flex-80 {
  width: 80%;
}
.flex-100 {
  width: 100%;
}

@include responsive(tab-land) {
  .md-order-inverse {
    order: 1;
  }

  .md-jc-center {
    justify-content: center;
  }

  .md-flex-col {
    flex-direction: column;
  }

  .md-flex-100 {
    width: 100% !important;
  }

  .md-flex-50 {
    width: 50% !important;
  }

  .md-flex-auto {
    width: auto;
  }
}

@include responsive(tab-port) {
  .sm-order-inverse {
    order: 1;
  }

  .sm-flex-col {
    flex-direction: column;
  }

  .sm-flex-100 {
    width: 100% !important;
  }

  .sm-flex-50 {
    width: 50% !important;
  }

  .sm-full-100 {
    width: 100% !important;
  }
}

@include responsive(phone) {
  .xs-order-inverse {
    order: 1;
  }

  .xs-flex-col {
    flex-direction: column;
  }

  .xs-hidden {
    display: none;
  }

  .xs-full-width {
    width: 100% !important;
  }

  .xs-flex-100 {
    width: 100% !important;
  }

  .xs-flex-50 {
    width: 50% !important;
  }

  .xs-jc-start {
    justify-content: flex-start;
  }
}
