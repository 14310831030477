$green : #3aaf50;
$red : #d0021b;
$gray : #cdcdcd;
$white : #fff;

.icon-btn{
  background-color: $gray;
  width: 3rem;
  height: 3rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  .icon{
    //max-width: 3rem;
    max-width: 1.5rem;
  }
  &.transparent{
    background-color: transparent;
  }
  &.green{
    background-color: $green;
  }
  &.red{
    background-color: $red;
  }

  &.white{
    background-color: $white;
  }
  &.transparent{
    background-color: transparent;
  }
  //1400
  @media only screen and (max-width: 87.5em) {
    width: 3.3rem;
    height: 3.3rem;
    .icon{
      max-width: 1.7rem;
    }
  }
}

