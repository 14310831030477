.file-uploader {
    margin-bottom: 1rem;
}

.file-uploader-main {
    width: 120px;
    height: 120px;
    border-style: solid;
    border-color: #d3d3d3;
    border-width: 2.5px;
    border-radius: 10px;
    position: relative;
}

.file-uploader-main.overlay {
    border-style: dashed;
}

.file-uploader-overlay i{
    font-size: 20px;
    position: relative;
    left: 5px;
}


.file-uploader-input {
    width: 100%;
    height: 100%;
}

.file-uploader-input input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    z-index: 2;
}


.file-uploader-input input:hover {
    cursor: pointer;
}

.file-uploader-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #dad8d845;
    color: #3e3e3e94;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
}


.file-uploader-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    height: 100%;
}

.file-uploader-text {
    width: 90%;
    text-align: center;
    font-size: 1rem;
    color: #5b5b5b;
}

.file-uploader-loading {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.file-uploader-loading .progress {
    width: 80%;
}

.file-uploader-cancel {
    display: none;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    transition: all 0.8s;
}

.file-uploader-cancel:hover {
    cursor: pointer;
    background-color: #3532326e;
}

.file-uploader-cancel i {
    font-size: 45px;
    color: #f2f1f0eb;
    transition: all 0.8s;
}

.file-uploader-cancel:hover i {
    color: #e1ded9eb;
}

.file-uploader-main:hover .file-uploader-cancel{
    display: flex;
}