.search-field{
  fieldset{
    border: none;
    display: inline-block;
    position: relative;
    width: 100%;
    max-width: 350px;
    .search-icon{
      position: absolute;
      top: 10px;
      left: 13px;
      width: 16px;
      //width: 2.3rem;
      img{
        width: 100%;
      }
    }
    input{
      width: 100%;
      max-width: 350px;
      padding: 0 15px 0 40px;
      //border: 1px solid #dfe1e7;
      color: #000000;
      border: 1px solid #898989;
      border-radius: 3px;
      font-size: 14px;
      font-weight: 600;
      height: 38px;
      font-family: inherit;
      &:focus{
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.25);
      }
       &::placeholder {
         color: #898989;
       }
       &:-ms-input-placeholder {
         color: #898989;
       }

       &::-ms-input-placeholder {
         color: #898989;
       }
    }
    &.event-search{
      max-width: 255px;
      input{
        max-width: 255px;
        border: 1px solid #e8ebf4;
        box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.2);
        font-size: 12px;
        &::placeholder {
          color: #898989;
        }
        &:-ms-input-placeholder {
          color: #898989;
        }

        &::-ms-input-placeholder {
          color: #898989;
        }
      }
    }
  }
  //600
  @media only screen and (max-width: 56.25em) {
    fieldset{
      max-width: 100% !important;
      input{
        max-width: 100% !important;
      }
    }
  }
}
