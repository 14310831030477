$default : #898989;
$green : #3aaf50;
$purple : #223150;
$red : #d0021b;
$white : #ffffff;

$white : #ffffff;

.secondary-btn,
.primary-btn{
  min-width: 17rem;
  border: 1px solid $default;
  border-radius: 3px;
  color: $white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  font-size: 1.8rem;
  font-family: inherit;
  font-weight: bold;
  text-transform: capitalize;
  background-color: $default;
  transition: all .5s ease-in-out ;
  -webkit-box-shadow: -2.5px 4px 9px -5px rgba(0,0,0,0.6);
  -moz-box-shadow: -2.5px 4px 9px -5px rgba(0,0,0,0.6);
  box-shadow: -2.5px 4px 9px -5px rgba(0,0,0,0.6);
  .icon{
    max-width: 3.5rem;
    margin-right: 1.2rem;
    img{
      width: 100%;
    }
  }
  &.file-btn{
    position: relative;
    input{
      width: 100%;
      position: absolute;
      height: 100%;
      opacity: 0;
    }
  }
  &.out-line{
    background-color: transparent !important;
    color: inherit;
    &:active,
    &:focus{
      color: inherit;
    }
  }
  &.big{
    border: 2px solid $default;
    font-size: 2.4rem;
    padding: 14px 18px;
    min-width: 21rem;
  }
  &.green-gradient{
    background-image: linear-gradient($green, #26651e);
    border: none;
    -webkit-box-shadow: -5px 6px 13px -5px rgba(0,0,0,0.69);
    -moz-box-shadow: -5px 6px 13px -5px rgba(0,0,0,0.69);
    box-shadow: -5px 6px 13px -5px rgba(0,0,0,0.69);
  }
  &.green{
    border: 1px solid $green;
    background-color: $green;
    &.out-line{
      color: $green;
    }
    &.big{
      border: 2px solid $green;
    }
  }
  &.purple{
    border: 1px solid $purple;
    background-color: $purple;
    &.out-line{
      color: $purple;
    }
    &.big{
      border: 2px solid $purple;
    }
  }
  &.red{
    border: 1px solid $red;
    background-color: $red;
    &.out-line{
      color: $red;
    }
    &.big{
      border: 2px solid $red;
    }
  }
  &.right-icon{
    .icon{
      order: 1;
      margin-left: 1.2rem;
      margin-right: 0;
    }
  }
  &.spacing{
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  &:active,
  &:focus{
    color: #fff;
  }

  //1400
  @media only screen and (max-width: 87.5em) {
    min-width: 11.2rem;
    padding: 0 10px;
    border-radius: 2px;
    height: 38px;
    font-size: 14px;
    .icon{
      max-width: 2.5rem;
      margin-right: 1rem;
    }
    &.medium{
      height: 42px;
      padding: 0 18px;
      font-size: 15px;
      //font-weight: 600;
    }
    &.big{
      height: 55px;
      padding: 0 18px;
      border-radius: 5px;
      font-size: 17px;
      //font-weight: 600;
    }
  }
}


