.account {
  &__logo {
    font-size: 0;
    position: absolute;
    top: 5rem;
    left: 5rem;
    img {
      width: 250px;
    }
  }
  &__left {
    background-image: url("../../assets/images/bg.jpg");
    background-size: cover;
  }
  &__right {
    background-color: #fff;
    overflow: hidden;
    overflow-y: auto;
    height: 100vh;
  }
  &__form {
    min-height: calc(100vh - 7rem);
  }
  &__have {
    height: 7rem;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 5rem;
  }

  //1400
  @media only screen and (max-width: 87.5em) {
    &__logo {
      top: 4rem;
      left: 4rem;
      img {
        width: 182px;
      }
    }
    &__have {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 56.25em) {
    &__have {
      justify-content: space-between;
      height: 7rem;
    }
    .account__logo {
      position: relative;
      top: initial;
      left: initial;
      max-width: 20rem;
      display: inline-block !important;
    }
  }

  @media only screen and (max-width: 37.5em) {
    &__form {
      min-height: calc(100vh - 12rem);
    }
    &__have {
      justify-content: center;
      height: 12rem;
    }
    .account__logo {
      padding: 2rem 0;
    }
  }
}
