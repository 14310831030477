.dashboard{
  .main-content{
    background-color: #f4f6fc;
    min-height: 100vh;
    padding:  5rem ;
    overflow: hidden;
  }

  .sidebar-toggle-btn{
    position: fixed;
    height: 50px;
    top:0;
    left: 0;
    z-index: 100;;
    width: 100%;
    background: #223150;
    .icon-btn{
      background-color: transparent;
      width: 40px;
      //height: 40px;
      margin:  8px 0 8px 20px;
      img{
        max-width: 22px;
      }
    }
  }

  .overlay-sidebar{
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    width: 100%;
    height: 100vh;
    top:0;
    left: 0;
    z-index: 90;
    display: none;
  }
  // 1400
  @media only screen and (max-width: 87.5em) {
    .main-content {
      padding: 3.5rem 5rem;
    }
  }

  //900
  @media only screen and (max-width: 56.25em) {
    margin-top: 50px;
    .sidebar{
      position: fixed;
      top: 0;
      left: 0;
      transform: translateX(-250px);
      transition: all .5s;
      z-index: 99;
      height: 100vh;
    }
    &.sidebar-active{
      .overlay-sidebar{
        display: block;
      }
      .sidebar{
        transform: translateX(0);
        .sidebar-container{
         padding-top: 50px;
        }
      }
      .sidebar-toggle-btn{
        background-color: transparent;
      }
    }
  }
}
