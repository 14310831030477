.type-head{
  border: none;
  margin: 1.5rem 0;
  // 1400
  @media only screen and (max-width: 87.5em) {
    .css-2o5izw,
    .css-vj8t7z{
      line-height: 3rem;
    }
  }
}
