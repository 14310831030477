@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800,900");

$primary: #3aaf50;
$primary-layer: rgba($primary, 0.8);
$secondary: #223150;
$danger: #db3c4f;

$white-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);

$default-text-color: #000000;
$secondary-text-color: #808486;

$gray-text: #898989;

$white: #ffffff;
$black: #000000;
$gray: #565656;

$primary-font-family: "Montserrat", sans-serif;

$space: 3rem;
$space-xs: 1.5rem;
$space-sm: 4rem;
$space-md: 6rem;
$space-lg: 7rem;
$space-xlg: 10rem;
