@include responsive(tab-port){
  .sm-hidden{
    display: none;
  }

  .sm-visible{
    display: initial;
  }

  .sm-flex-100{
    width: 100%;
  }

}