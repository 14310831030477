.companies-input {
    width: 95%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    &.first {
        margin: 0;
        width: 100%;
        display: flex;
        justify-content: space-around;
    }

    &.profile-container {
        flex-direction: row;
    }

    &.phone-status {
        display: flex;
        flex-direction: row;

        & .phone-status__text {
            font-weight: 500;

            span {
                font-weight: 600;
                font-size: 1.6rem;
            }
        }
    }

    &.disable {
        fieldset {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    &__fieldset {
        margin: 0;
    }

    &__checkbox {
        display: flex;
        flex-direction: column;

        strong {
            font-weight: 800;
        }
    }

    & .field-set.gray input {
        // border: 2px solid #898989;
        // background-color: #e7e7e7;
        // pointer-events: none;

        &:focus {
            outline: none;
        }
    }

    &__icon-container {
        position: absolute;
        top: 0;
        right: 0;
        width: 4rem;
        height: 100%;
        background-color: #223150;
        display: flex;
        justify-content: center;
        align-items: center;

        &.gray {
            background-color: #898989;
            pointer-events: none;
        }

        &:hover {
            cursor: pointer;
        }

        img {
            width: 2rem;
        }

        &.loading img {
            -webkit-animation: loading360 1s ease infinite;
            animation: loading360 1s ease infinite;
        }
        // New Screen CSS
        &.icon-del {
            display: none;
        }
    }

    &__cross {
        position: absolute;
        right: 8rem;
        top: 50%;
        transform: translateY(-50%);

        &:hover {
            cursor: pointer;
        }
    }

    & .company-logo {
        padding-left: 2.3rem;
    }
}

#segment input {
    line-height: 4.5rem;
}

@-webkit-keyframes loading360 {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes loading360 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.logo-user-sec {
    width: 20%;
}

.form-col {
    width: 80%;
}
.form-sec {
    & .form-field-sec {
        background-color: #ffffff;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        padding: 40px;
    }
    & .icon-del {
        display: none;
    }
    & .align-f-end {
        align-items: flex-end;
    }
    & fieldset {
        margin: 0px !important;
    }
    & label {
        color: #1d3152 !important;
        font-family: Montserrat;
        font-size: 12px !important;
        font-weight: 500;
        letter-spacing: 0 !important;
        line-height: 16px;
        margin-bottom: 5px;
    }
    & .field-set .input-file,
    & .field-set select,
    & .field-set input {
        color: #1d3152;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        border: 1px solid #a7b9c5 !important;
        border-radius: 4px;
        line-height: 37px;
        background-color: #fff;
        padding: 0px 10px;
    }
    & .error {
        .input-file,
        select,
        input {
            &,
            &:focus {
                border-color: #d0021b !important;
            }
        }
    }

    & .select-box__border {
        border: 1px solid #a7b9c5;
        border-radius: 4px;
        padding: 1px;
    }

    & .radio-checkbox-row .companies-input {
        width: 165px !important;
        margin: 0;
    }
    & .radio-checkbox-row {
        justify-content: flex-start;
        align-items: flex-start;
    }

    & .radio-checkbox-row .companies-input:nth-child(2) {
        width: 265px !important;
    }

    & .radio-checkbox-row .companies-input:nth-child(3) {
        width: 345px !important;
    }
    & .act-type-btn .m-t-xs {
        margin-top: 0px;
    }

    #segment input {
        border: 0px !important;
    }
    & .file-uploader-main {
        width: 150px;
        height: 150px;
    }
    & .gray-text {
        color: #a7b9c5 !important;
        font-family: Montserrat;
        font-size: 14px !important;
        font-weight: 600;
        letter-spacing: 0 !important;
        line-height: 16px;
    }
    & .users-sec ul li {
        display: block;
        color: #1d3152;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        margin-bottom: 10px;
    }
    & .logo-user-sec button {
        height: 30.5px !important;
        border-radius: 3px;
        box-shadow: none;
    }

    & .logo-user-sec button span {
        color: #ffffff;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 15px;
        text-align: center;
    }
    & .users-sec strong {
        display: block;
        margin-bottom: 10px;
    }
    & .users-sec ul {
        margin-bottom: 22px;
        max-height: 500px;
        overflow: auto;
    }
    & .logo-col {
        margin-bottom: 20px !important;
    }
    & .company-row label {
        margin-bottom: 10px;
    }
    & .company-row h2 {
        color: #1d3152;
        font-family: Montserrat;
        font-size: 26px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 34px;
        padding: 0;
        height: auto !important;
        border: 0px !important;
    }
    & .company-row {
        align-items: stretch;
        flex-direction: row;
        margin-bottom: 20px;
        .companyTitle {
            position: relative;
            input {
                color: #1d3152;
                font-family: "Montserrat";
                font-weight: bold;
                font-size: 28px;
                line-height: 34px;
                padding-left: 0;
                outline: none;
                border: none !important;
                padding-right: 25px;
            }
            &::after {
                content: "";
                background: url("../../../assets//svg-icon/pen.png") no-repeat;
                background-size: contain;
                width: 18px;
                height: 18px;
                position: absolute;
                pointer-events: none;
                right: 0;
                top: 6px;
            }
            & > input:focus::after {
                opacity: 0;
            }
        }
    }
    & .company-row .camp-age {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    & .company-row .camp-age:last-child {
        justify-content: flex-end;
        margin-bottom: 4px;
        padding-left: 60px;
    }
    & .company-row .camp-age strong {
        font-weight: 600;
        font-size: 16px;
    }
    & .company-row svg {
        font-size: 20px;
    }
    & .sec-label {
        margin-top: 20px;
        margin-bottom: 20px;
        display: block;
    }
    & .checkbox__box {
        width: 18px;
        height: 18px;
        border: 2px solid #1d3152;
        border-radius: 2px !important;
        margin-right: 4px;
    }
    & .checkbox label .sec-checkboc {
        color: #1d3152;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
    }

    & .webChatSection {
        position: relative;
        .spinner-loading {
            position: absolute;
            left: 120px;
        }
    }

    & .club-web-chat-sec label,
    .companies-input p {
        color: #1d3152 !important;
        font-family: Montserrat;
        font-size: 10px !important;
        font-weight: 500;
        letter-spacing: 0 !important;
        line-height: 18px;
        text-transform: uppercase;
    }
    & .club-web-chat-sec .column-3:not(:last-child) {
        margin-right: 2%;
    }

    & .club-web-chat-sec .column-3 {
        flex: 0 0 23%;
        max-width: 23%;
    }
    & .club-web-chat-sec {
        padding-left: 32px;
        margin-top: 15px;
    }
    & .number-row {
        margin-bottom: 30px;
    }
    & .club-web-chat-sec .companies-input label {
        margin-right: 0;
    }
    & .club-web-chat-sec .companies-input label {
        margin-right: 3px;
        text-transform: capitalize;
    }
    & .companies-input p {
        margin-bottom: 5px !important;
    }
    & .disable fieldset,
    & .disable a,
    & .disable .add-websie-btn {
        opacity: 0.5;
        pointer-events: none;
    }

    & .segment-sec .basic-multi-select .select__control .select__value-container--is-multi {
        border: 1px solid #a7b9c5;
        border-radius: 4px;
        min-height: 100px;
        align-items: flex-start;
        padding-top: 5px;
    }
    & .segment-sec .select-box__border {
        border: 0;
    }
    & .segment-sec {
        margin-top: 20px;
        margin-bottom: 25px;
    }
    & .segment-sec .companies-input {
        width: 47%;
        margin-right: 6%;
    }
    & .web-chat-sec {
        align-items: flex-end;
    }
    & .web-chat-sec .column-4 {
        padding-right: 15px;
    }
    & .webchat-checkbox .companies-input {
        width: 100%;
    }

    & .webchat-item {
        width: 600px;
        &:not(:last-child) {
            border-bottom: 1px solid #00000017;
            margin-bottom: 15px;
            padding-bottom: 10px;
        }
        &:only-child {
            .deleteWebsiteBtn{
                display: none;
            }
        }
    }
    & .webchat-add {
        padding-left: 32px;
        margin-top: 15px;
    }

    & .web-chat-sec a .copy-code {
        border-radius: 3px;
        background-color: #1d3152;
        color: #ffffff;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 15px;
        text-align: center;
        padding: 8px 18px;
        display: table;
    }
    & .web-chat-sec span.copied-url {
        border: 1px solid #007fed;
        border-radius: 3px;
        background-color: rgba(0, 127, 237, 0.1);
        color: #1d3152;
        font-family: "Montserrat";
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 20px;
        padding: 4px 18px;
        display: table;
        margin-bottom: 3px;
    }
    & button.add-websie-btn {
        border-radius: 3px;
        background-color: #3aaf50;
        border: 0px;
        color: #ffffff;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 15px;
        text-align: center;
        display: flex;
        align-items: center;
        padding: 8px 11px;
        margin-bottom: 30px;
    }
    & button.add-websie-btn svg {
        color: #fff;
        font-size: 15px;
        margin-right: 8px;
    }
    & .clubbooth-sec {
        width: 100%;
    }

    & .clubbooth-sec .companies-input {
        width: 100%;
    }
    & fieldset.radio-button svg,
    & .icon-check svg {
        font-size: 14px;
        margin-top: 5px;
    }
    & .radio-button__label-active svg,
    & .icon-check svg {
        color: #246b47 !important;
    }
    & .radio-button__label-inactive svg {
        color: #748997 !important;
    }
    & .check-row .checkbox__box {
        width: 12px;
        height: 12px;
        border: 1.9px solid #748997;
        border-radius: 50px !important;
        margin-right: 4px;
    }
    & .check-row .checkbox__box .icon-check {
        width: 14px !important;
        height: 14px;
        position: static;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    & .check-row label.checkbox__box svg {
        margin: 0px !important;
    }
    & .check-row .checkbox .checkbox__box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    & .check-row .checkbox .checkbox__input:checked + .checkbox__box {
        background-color: transparent;
        border: 0;
    }
    & .check-row .companies-input__checkbox {
        margin-top: 8px;
    }
    & .comp-form-header h2 {
        color: #1d3152;
        font-family: Montserrat;
        font-size: 28px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 34px;
        margin: 0px !important;
    }
    & .comp-form-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
    & .comp-form-header .cancel-btn {
        margin-right: 15px;
        &:hover {
            background-color: #d0021b;
        }
    }

    & .comp-form-header button {
        border-radius: 3px;
        transition: 0.3s;
        background-color: #1d3152;
        border-color: #1d3152;
        padding: 8px 16px;
        height: auto !important;
        min-width: auto;
        color: #ffffff;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 15px;
        text-align: center;
        box-shadow: none;
    }
    & .comp-form-header button:hover {
        border-color: #3aaf50;
        background-color: #3aaf50;
    }
    & .comp-form-header button.disabled {
        opacity: 0.4;
    }

    & .web-chat-sec {
        margin-bottom: 12px;
    }
    @media only screen and (min-width: 1600px) {
        & .company-row h2 {
            font-size: 28px;
        }
        & .club-web-chat-sec label,
        & .companies-input p {
            font-size: 14px !important;
        }
    }
}
.d-flex-row {
    display: flex;
    align-items: flex-start;
}
.column-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}
.column-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}
.column-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}
.column-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}
.column-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}
.column-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}
.column-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}
.column-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}
.column-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}
.column-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}
.column-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
}
.column-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}
