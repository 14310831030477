.primary-heading{
  font-size: 2.8rem;
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  &:before{
    content: '';
    position: absolute;
    top: -15px;
    left: 50%;
    width: 11rem;
    height: 3px;
    transition: all .5s;
    transform: translateX(-50%);
    background-color: $primary;
  }
  &.white{
    color: $white;
    &:before{
      background-color: $white;
    }
  }
  &:hover{
    &:before{
      width: 13rem;
    }
  }
}