.rb-dialog {
    width: 100%;
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.7);
    &__content {
        width: 90%;
        max-width: 75rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &__body {
        width: 100%;
        min-height: 200px;
        border-radius: 5px;
        padding: 4rem;
        background-color: #fff;
        transition: all 0.8s;
    }
    &.m-w-948 .rb-dialog__content {
        max-width: 94.8rem;
        top: 20%;
        transform: translate(-50%, -20%);
    }
    &.active {
        opacity: 1;
        visibility: visible;
        .rb-dialog__body {
            transform: translateY(0);
        }
    }
    &.dialog-modal {
        .rb-dialog__body {
            padding-right: 0;
        }
        .dialog-modal__container {
            max-height: 85vh;
        }
        .close-dialog {
            position: absolute;
            top: 10px;
            right: 10px;
            img {
                width: 2.5rem;
            }
        }
    }
    &.scrolling {
        .rb-dialog__body {
            padding: 30px 0 20px 20px;
        }
        .dialog-modal__container {
            padding-right: 20px;
            overflow-x: hidden;
            overflow-y: auto;
            &::-webkit-scrollbar-track {
                background-color: transparent;
                border-radius: 10px;
            }
            &::-webkit-scrollbar {
                width: 4px;
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: transparent;
                transition: background-color 0.3s;
            }
            &:hover {
                &::-webkit-scrollbar-thumb {
                    background-color: rgba(#3aaf50, 0.8);
                }
            }
        }
    }
}
.rb-dialog__body-content {
    &-icon {
        width: 100%;
        max-width: 110px;
        max-height: 110px;
        margin-bottom: 20px;
        min-height: 100px;
    }
    &-title {
        font-size: 2.5rem;
    }
}

// impersonate dialog content scss
.impersonate-dialog {
    &__image {
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;
    }

    &__text {
        display: flex;
        justify-content: center;
        font-size: 4rem;
        margin-bottom: 2rem;
    }

    &__section {
        display: flex;
        justify-content: space-evenly;
        width: 90%;
        margin: 0 auto;

        &-btn {
            width: 22.3rem;
            height: 15rem;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
                0 3px 14px 2px rgba(0, 0, 0, 0.12);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-right: 10px;

            &:hover {
                cursor: pointer;
            }

            & .btn-img {
                width: 14.2rem;
                margin-bottom: 0.4rem;

                img {
                    width: 100%;
                }
            }

            & p {
                font-size: 2rem;

                strong {
                    font-weight: 800;
                    text-transform: uppercase;
                }
            }
        }
    }
}

.impersonate {
    .rb-dialog__body {
        padding-right: 4rem !important;
    }

    &.rb-dialog .rb-dialog__content {
        top: 20%;
        transform: translate(-50%, -20%);
    }
}

// 1400
@media only screen and (max-width: 87.5em) {
    .rb-dialog {
        &.dialog-modal {
            .close-dialog {
                img {
                    width: 2rem;
                }
            }
        }
    }
}
