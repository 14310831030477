@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800,900");
@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  /*Now convert all px value into rem dependent on root font-size  */
  /*font-size: 10px; */
  /*Set root font-size is 10px because  calculate rem is easy 10px == 1rem*/
  /*default root font size is 16px*/
  font-size: 62.5%;
  /* (10/16) == 0.625 => 0.625*100 == 62.5%*/
}
@media only screen and (max-width: 87.5em) {
  html {
    font-size: 56.25%;
  }
}
@media only screen and (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}

body {
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
}

.container {
  width: 80%;
  max-width: 1600px;
  margin: 0 auto;
}

.container-p-l {
  padding-left: 10%;
}

.container-p-r {
  padding-right: 10%;
}

.container-m-l {
  margin-left: 10%;
}

.container-m-r {
  margin-right: 10%;
}

a, button {
  color: inherit;
  text-decoration: none;
}
a:focus, a:active, button:focus, button:active {
  color: inherit;
  outline: none;
}
a:hover, button:hover {
  cursor: pointer;
}

ul {
  list-style: none;
}
ul li {
  display: inline-block;
}

.m-a {
  margin-left: auto;
  margin-right: auto;
}

.m {
  margin: 3rem;
}

.m-x {
  margin-left: 3rem;
  margin-right: 3rem;
}

.m-y {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.m-l {
  margin-left: 3rem;
}

.m-r {
  margin-right: 3rem;
}

.m-t {
  margin-top: 3rem;
}

.m-b {
  margin-bottom: 3rem;
}

.m-xs {
  margin: 1.5rem;
}

.m-x-xs {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.m-y-xs {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.m-l-xs {
  margin-left: 1.5rem;
}

.m-r-xs {
  margin-right: 1.5rem;
}

.m-t-xs {
  margin-top: 1.5rem;
}

.m-b-xs {
  margin-bottom: 1.5rem !important;
}

.m-sm {
  margin: 4rem;
}

.m-x-sm {
  margin-left: 4rem;
  margin-right: 4rem;
}

.m-y-sm {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.m-l-sm {
  margin-left: 4rem;
}

.m-r-sm {
  margin-right: 4rem;
}

.m-t-sm {
  margin-top: 4rem;
}

.m-b-sm {
  margin-bottom: 4rem;
}

.m-md {
  margin: 6rem;
}

.m-x-md {
  margin-left: 6rem;
  margin-right: 6rem;
}

.m-y-md {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.m-l-md {
  margin-left: 6rem;
}

.m-r-md {
  margin-right: 6rem;
}

.m-t-md {
  margin-top: 6rem;
}

.m-b-md {
  margin-bottom: 6rem;
}

.m-lg {
  margin: 7rem;
}

.m-x-lg {
  margin-left: 7rem;
  margin-right: 7rem;
}

.m-y-lg {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.m-l-lg {
  margin-left: 7rem;
}

.m-r-lg {
  margin-right: 7rem;
}

.m-t-lg {
  margin-top: 7rem;
}

.m-b-lg {
  margin-bottom: 7rem;
}

.m-xlg {
  margin: 10rem;
}

.m-x-xlg {
  margin-left: 10rem;
  margin-right: 10rem;
}

.m-y-xlg {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.m-l-xlg {
  margin-left: 10rem;
}

.m-r-xlg {
  margin-right: 10rem;
}

.m-t-xlg {
  margin-top: 10rem;
}

.m-b-xlg {
  margin-bottom: 10rem;
}

.m-t-42 {
  margin-top: 4.2rem !important;
}

.p {
  padding: 3rem;
}

.p-x {
  padding-left: 3rem;
  padding-right: 3rem;
}

.p-y {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.p-l {
  padding-left: 3rem;
}

.p-r {
  padding-right: 3rem;
}

.p-t {
  padding-top: 3rem;
}

.p-b {
  padding-bottom: 3rem;
}

.p-xs {
  padding: 1.5rem;
}

.p-x-xs {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.p-y-xs {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.p-l-xs {
  padding-left: 1.5rem;
}

.p-r-xs {
  padding-right: 1.5rem;
}

.p-t-xs {
  padding-top: 1.5rem;
}

.p-b-xs {
  padding-bottom: 1.5rem;
}

.p-sm {
  padding: 4rem;
}

.p-x-sm {
  padding-left: 4rem;
  padding-right: 4rem;
}

.p-y-sm {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.p-l-sm {
  padding-left: 4rem;
}

.p-r-sm {
  padding-right: 4rem;
}

.p-t-sm {
  padding-top: 4rem;
}

.p-b-sm {
  padding-bottom: 4rem;
}

.p-md {
  padding: 6rem;
}

.p-x-md {
  padding-left: 6rem;
  padding-right: 6rem;
}

.p-y-md {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.p-l-md {
  padding-left: 6rem;
}

.p-r-md {
  padding-right: 6rem;
}

.p-t-md {
  padding-top: 6rem;
}

.p-b-md {
  padding-bottom: 6rem;
}

.p-lg {
  padding: 7rem;
}

.p-x-lg {
  padding-left: 7rem;
  padding-right: 7rem;
}

.p-y-lg {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.p-l-lg {
  padding-left: 7rem;
}

.p-r-lg {
  padding-right: 7rem;
}

.p-t-lg {
  padding-top: 7rem;
}

.p-b-lg {
  padding-bottom: 7rem;
}

.p-xlg {
  padding: 10rem;
}

.p-x-xlg {
  padding-left: 10rem;
  padding-right: 10rem;
}

.p-y-xlg {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.p-l-xlg {
  padding-left: 10rem;
}

.p-r-xlg {
  padding-right: 10rem;
}

.p-t-xlg {
  padding-top: 10rem;
}

.p-b-xlg {
  padding-bottom: 10rem;
}

.white-box {
  background-color: #ffffff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}

.fancy-scroll {
  overflow-y: auto;
}
.fancy-scroll::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
.fancy-scroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: transparent;
}
.fancy-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: transparent;
  transition: background-color 0.3s;
}
.fancy-scroll:hover::-webkit-scrollbar-thumb {
  background-color: rgba(58, 175, 80, 0.8);
}

.table-search {
  padding: 18px 20px;
}

.f-14 {
  font-size: 1.4rem;
}

.f-18 {
  font-size: 1.8rem;
  line-height: 2.8rem;
}

.f-22 {
  font-size: 2.2rem;
  line-height: 3rem;
}

.f-24 {
  font-size: 2.4rem;
  line-height: 3.5rem;
}

.f-45 {
  font-size: 4.5rem;
}

.primary-text {
  color: #3aaf50;
}

.secondary-text {
  color: #223150;
}

.gray-text {
  color: #898989;
}

.hover-primary-text:hover {
  color: #3aaf50;
}

.white-text {
  color: #ffffff;
}

.black-text {
  color: #000000;
}

.danger-text {
  color: #db3c4f;
}

.light-gray-text {
  color: #898989;
}

.center {
  text-align: center;
}

.right {
  text-align: right !important;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.semi-bold {
  font-weight: 700;
}

.bold {
  font-weight: bold;
}

.bolder {
  font-weight: bolder;
}

.a {
  text-decoration: none;
  border-bottom: 1px solid transparent;
}
.a, .a:active, .a:focus {
  color: #64a0e6;
}
.a:hover {
  border-color: #64a0e6;
}
.a.border-none:hover {
  border-color: transparent;
}

.relative {
  position: relative;
}

.filter-gray {
  filter: grayscale(100%);
}

.rotate-90 {
  transform: rotate(90deg);
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.l-h-3, .article-l-h-3 p {
  line-height: 3rem;
}

.p-18, .article-p-18 p {
  font-size: 1.8rem;
  line-height: 2.4rem;
}

.hidden {
  display: none;
}

.h-100 {
  height: 100vh;
}

.bg-white {
  background-color: #ffffff;
}

.fixed {
  position: fixed !important;
}

.pointer {
  cursor: pointer;
}

.disabled:hover {
  cursor: not-allowed !important;
}

.flex {
  display: flex;
}

.flex-fs {
  display: flex;
  align-items: flex-start;
}

.flex-sb {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.flex-sb-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-sb-end {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.flex-sa {
  display: flex;
  justify-content: space-around;
}

.flex-e {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}

.flex-e-center {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.flex-e-end {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center-start {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-stretch {
  display: flex;
  align-items: stretch;
}

.inline-flex-start {
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.inline-flex-center {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.inline-flex-end {
  display: inline-flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.flex-align-stretch {
  align-items: stretch;
}

.flex-grow {
  flex-grow: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-col {
  flex-direction: column;
}

.align-self-center {
  align-self: center;
}

.flex-20 {
  width: 20%;
}

.flex-30 {
  width: 20%;
}

.flex-40 {
  width: 40%;
}

.flex-45 {
  width: 45%;
}

.flex-50 {
  width: 50%;
}

.flex-55 {
  width: 55%;
}

.flex-60 {
  width: 60%;
}

.flex-70 {
  width: 70%;
}

.flex-80 {
  width: 80%;
}

.flex-100 {
  width: 100%;
}

@media only screen and (max-width: 75em) {
  .md-order-inverse {
    order: 1;
  }

  .md-jc-center {
    justify-content: center;
  }

  .md-flex-col {
    flex-direction: column;
  }

  .md-flex-100 {
    width: 100% !important;
  }

  .md-flex-50 {
    width: 50% !important;
  }

  .md-flex-auto {
    width: auto;
  }
}
@media only screen and (max-width: 56.25em) {
  .sm-order-inverse {
    order: 1;
  }

  .sm-flex-col {
    flex-direction: column;
  }

  .sm-flex-100 {
    width: 100% !important;
  }

  .sm-flex-50 {
    width: 50% !important;
  }

  .sm-full-100 {
    width: 100% !important;
  }
}
@media only screen and (max-width: 37.5em) {
  .xs-order-inverse {
    order: 1;
  }

  .xs-flex-col {
    flex-direction: column;
  }

  .xs-hidden {
    display: none;
  }

  .xs-full-width {
    width: 100% !important;
  }

  .xs-flex-100 {
    width: 100% !important;
  }

  .xs-flex-50 {
    width: 50% !important;
  }

  .xs-jc-start {
    justify-content: flex-start;
  }
}
.primary-heading {
  font-size: 2.8rem;
  position: relative;
  display: inline-block;
  text-transform: uppercase;
}
.primary-heading:before {
  content: "";
  position: absolute;
  top: -15px;
  left: 50%;
  width: 11rem;
  height: 3px;
  transition: all 0.5s;
  transform: translateX(-50%);
  background-color: #3aaf50;
}
.primary-heading.white {
  color: #ffffff;
}
.primary-heading.white:before {
  background-color: #ffffff;
}
.primary-heading:hover:before {
  width: 13rem;
}

@media only screen and (max-width: 37.5em) {
  .xs-m-n-l {
    margin-left: 0;
  }

  .xs-m-n-r {
    margin-right: 0;
  }

  .xs-m-n-t {
    margin-top: 0;
  }

  .xs-m-n-b {
    margin-bottom: 0;
  }

  .xs-m-l {
    margin-left: 3rem;
  }

  .xs-m-r {
    margin-right: 3rem;
  }

  .xs-m-t {
    margin-top: 3rem;
  }

  .xs-m-b {
    margin-bottom: 3rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .xs-p-n-l {
    padding-left: 0;
  }

  .xs-p-n-r {
    padding-right: 0;
  }

  .xs-p-n-t {
    padding-top: 0;
  }

  .xs-p-n-b {
    padding-bottom: 0;
  }

  .xs-p-l {
    padding-left: 3rem;
  }

  .xs-p-r {
    padding-right: 3rem;
  }

  .xs-p-t {
    padding-top: 3rem;
  }

  .xs-p-b {
    padding-bottom: 3rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .xs-hidden {
    display: none;
  }

  .xs-visible {
    display: initial;
  }

  .xs-flex-100 {
    width: 100%;
  }

  .xs-flex-col {
    flex-direction: column;
  }

  .xs-flex-wrap {
    flex-wrap: wrap;
  }

  .xs-align-items-center {
    align-items: center;
  }

  .xs-m-a {
    margin: auto;
  }
}
@media only screen and (max-width: 56.25em) {
  .md-p-n-l {
    padding-left: 0;
  }

  .md-p-n-r {
    padding-right: 0;
  }

  .md-p-n-t {
    padding-top: 0;
  }

  .md-p-n-b {
    padding-bottom: 0;
  }

  .md-p-l {
    padding-left: 3rem;
  }

  .md-p-r {
    padding-right: 3rem;
  }

  .md-p-t {
    padding-top: 3rem;
  }

  .md-p-b {
    padding-bottom: 3rem;
  }
}
@media only screen and (max-width: 56.25em) {
  .sm-hidden {
    display: none;
  }

  .sm-visible {
    display: initial;
  }

  .sm-flex-100 {
    width: 100%;
  }
}