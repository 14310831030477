$primary : #3aaf50;
.checkbox {
  display: inline-flex;
  align-items: center;
  z-index: 1;
  &__input {
    display: none;
  }
  &__box {
    width: 2.5rem;
    height: 2.5rem;
    border: 2px solid #000000;
    background-color: transparent;
    display: inline-block;
    border-radius: 3px;
    position: relative;
    .icon-check {
      width: 1.8rem !important;
      position: absolute;
      top: 2px;
      left: 2px;
      visibility: hidden;
    }
  }
  &__label{
    margin-left: 1rem;
  }
  &.white{
    .checkbox__box{
      border: 2px solid #fff;
    }
  }
  .checkbox__input:checked + .checkbox__box {
    background-color: $primary;
    border-color: $primary;
  }

  .checkbox__input:checked + .checkbox__box .icon-check {
    visibility: visible;
  }
}
