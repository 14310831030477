.p{
  padding: $space;
}

.p-x{
  padding-left: $space;
  padding-right: $space;
}

.p-y{
  padding-top: $space;
  padding-bottom: $space;
}

.p-l{
  padding-left: $space;
}

.p-r{
  padding-right: $space;
}

.p-t{
  padding-top: $space;
}

.p-b{
  padding-bottom: $space;
}

.p-xs{
  padding: $space-xs;
}

.p-x-xs{
  padding-left: $space-xs;
  padding-right: $space-xs;
}

.p-y-xs{
  padding-top: $space-xs;
  padding-bottom: $space-xs;
}

.p-l-xs{
  padding-left: $space-xs;
}

.p-r-xs{
  padding-right: $space-xs;
}

.p-t-xs{
  padding-top: $space-xs;
}

.p-b-xs{
  padding-bottom: $space-xs;
}

.p-sm{
  padding: $space-sm;
}

.p-x-sm{
  padding-left: $space-sm;
  padding-right: $space-sm;
}

.p-y-sm{
  padding-top: $space-sm;
  padding-bottom: $space-sm;
}

.p-l-sm{
  padding-left: $space-sm;
}

.p-r-sm{
  padding-right: $space-sm;
}

.p-t-sm{
  padding-top: $space-sm;
}

.p-b-sm{
  padding-bottom: $space-sm;
}

.p-md{
  padding: $space-md;
}

.p-x-md{
  padding-left: $space-md;
  padding-right: $space-md;
}

.p-y-md{
  padding-top: $space-md;
  padding-bottom: $space-md;
}

.p-l-md{
  padding-left: $space-md;
}

.p-r-md{
  padding-right: $space-md;
}

.p-t-md{
  padding-top: $space-md;
}

.p-b-md{
  padding-bottom: $space-md;
}

.p-lg{
  padding: $space-lg;
}

.p-x-lg{
  padding-left: $space-lg;
  padding-right: $space-lg;
}

.p-y-lg{
  padding-top: $space-lg;
  padding-bottom: $space-lg;
}

.p-l-lg{
  padding-left: $space-lg;
}

.p-r-lg{
  padding-right: $space-lg;
}

.p-t-lg{
  padding-top: $space-lg;
}

.p-b-lg{
  padding-bottom: $space-lg;
}

.p-xlg{
  padding: $space-xlg;
}

.p-x-xlg{
  padding-left: $space-xlg;
  padding-right: $space-xlg;
}

.p-y-xlg{
  padding-top: $space-xlg;
  padding-bottom: $space-xlg;
}

.p-l-xlg{
  padding-left: $space-xlg;
}

.p-r-xlg{
  padding-right: $space-xlg;
}

.p-t-xlg{
  padding-top: $space-xlg;
}

.p-b-xlg{
  padding-bottom: $space-xlg;
}