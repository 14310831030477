@include responsive(phone){

  .xs-p-n-l{
    padding-left: 0 ;
  }
  .xs-p-n-r{
    padding-right: 0 ;
  }
  .xs-p-n-t{
    padding-top: 0 ;
  }
  .xs-p-n-b{
    padding-bottom: 0 ;
  }

  .xs-p-l{
    padding-left: 3rem;
  }
  .xs-p-r{
    padding-right: 3rem;
  }
  .xs-p-t{
    padding-top: 3rem;
  }
  .xs-p-b{
    padding-bottom: 3rem ;
  }
}