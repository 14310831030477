$primary: #3aaf50;
$secondary: #223150;
.sidebar {
    width: 300px;
    min-width: 300px;
    //min-height: 100vh;
    //height: 100%;
    // background-image: linear-gradient($secondary, #071534);
    z-index: 99;
    height: 100vh;

    &-fixed-container {
        position: fixed;
        width: inherit;
        height: 100vh;
        overflow-y: auto;
        background-image: linear-gradient($secondary, #071534f0);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &-btn {
        display: flex;
        margin-bottom: 3rem;
        margin-left: 2.7rem;
        font-size: 2rem;
        text-transform: uppercase;
        color: #d0021b;

        & .icon {
            margin-right: 1rem;
        }

        & .text {
            font-weight: 700;
            transform: translateY(7px);
        }

        &:hover {
            cursor: pointer;
        }
    }

    .create-event-btn {
        margin-top: 50px;
        margin-bottom: 50px;
        button {
            font-size: 2rem;
        }
    }
    nav {
        ul {
            li {
                display: block;
                a {
                    color: #fff;
                    height: 80px;
                    padding-left: 40px;
                    position: relative;
                    font-size: 2rem;
                    .icon {
                        display: inline-block;
                        margin-right: 25px;
                        width: 3.4rem;
                        &.hover-icon {
                            display: none;
                        }
                    }
                    &:before {
                        content: "";
                        width: 5px;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    &.active {
                        background-color: #ffffff;
                        color: $secondary;
                        &:before {
                            background-color: $primary;
                        }
                        .icon {
                            &.default-icon {
                                display: none;
                            }
                            &.hover-icon {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }

    // 1400
    @media only screen and (max-width: 87.5em) {
        width: 240px;
        min-width: 240px;
        nav {
            ul {
                li {
                    a {
                        color: #fff;
                        height: 7rem;
                        padding-left: 2.5rem;
                        .icon {
                            margin-right: 1.5rem;
                            width: 2.5rem;
                        }
                    }
                }
            }
        }
    }
}
