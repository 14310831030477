@include responsive(phone){

  .xs-m-n-l{
    margin-left: 0 ;
  }
  .xs-m-n-r{
    margin-right: 0 ;
  }
  .xs-m-n-t{
    margin-top: 0 ;
  }
  .xs-m-n-b{
    margin-bottom: 0 ;
  }

  .xs-m-l{
    margin-left: 3rem;
  }
  .xs-m-r{
    margin-right: 3rem;
  }
  .xs-m-t{
    margin-top: 3rem;
  }
  .xs-m-b{
    margin-bottom: 3rem ;
  }
}