.white-box{
  background-color: $white;
  box-shadow: $white-box-shadow;
  border-radius: 3px;
}

.fancy-scroll{
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: transparent;
    transition: background-color .3s;
  }
  &:hover{
    &::-webkit-scrollbar-thumb {
      background-color: rgba($primary, 0.8);
    }
  }
}

.table-search{
  padding: 18px 20px;
}
