$secondary : #223150;
.table-section{
  overflow: hidden;
  overflow-x: auto;
  table{
    //table-layout:fixed;
    border-collapse: collapse;
    .checkbox-col{
      width: 100px;
      text-align: center;
    }
    tr{
      th, td{
        padding: 2rem;
        text-align: left;
        &:first-child{
          padding-left: 3rem;
        }
      }
    }
    thead{
      background-color: $secondary;
      tr{
        th, td{
          text-transform: uppercase;
          color: #fff;
        .icon-btn{
          // display: none;
          background-color: transparent;
          width: 2.2rem;
          height: 2.2rem;
          position: relative;
          top:3px;
          img{
            width: 2rem;
          }
        }
        }
      }
    }
    tbody{
      tr{
        background-color: #fff;
        &:nth-child(even){
          background-color: #f3f3f3;
        }
        .no-data{
          padding: 8rem 0;
          text-align: center;
        }
      }
    }
  }
  &.infinite-scroll{
    max-height: 500px;
    overflow: auto;
    thead{
      tr{
        th, td{
          position: sticky;
          top: 0;
          z-index: 10;
          background-color: $secondary;
        }
      }
    }
  }
  &.no-scroll{
    overflow: initial;
    overflow-y: inherit;
  }
  //1400
  @media only screen and (max-width: 87.5em) {
    table{
      thead{
        tr{
          th, td{
            font-size: 18px;
          }
        }
      }
      tr{
        th, td{
          padding: 14px;
          font-size: 16px;
          &:first-child{
            padding-left: 38px;
          }
        }
      }
    }

  }
  @media only screen and (max-width: 1000px) {
    table {
      table-layout: initial;
    }
  }
  @media only screen and (max-width: 56.25em) {
    overflow-x: auto !important;
    table{
      thead{
        tr{
          th, td{
            font-size: 16px;
          }
        }
      }
      tr{
        th, td{
          padding: 10px 15px;
          font-size: 15px;
          &:first-child{
            padding-left: 10px;
          }
        }
      }
    }
  }
}
