$drop-down-menu-hover-text : #c3e7ca;
$drop-down-shadow : 0 3px 5px rgba(0, 0, 0, 0.25);

.drop-down-menu {
  position: absolute;
  width: 180px;
  //height: 150px;
  padding: 15px 0;
  top: 10px;
  right: 0;
  opacity: 0;
  visibility: hidden;
  box-shadow: $drop-down-shadow;
  border-radius: 4px;
  a,
  button {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 0 15px 0 15px;
    border: none;
    background-color: transparent;
    font-size: 15px;
    position: relative;
    font-family: inherit;
    .svg-icon{
      position: absolute;
      left: 15px;
    }
    .icon{
      padding-right: 40px;
    }
    &:hover {
      background-color: $drop-down-menu-hover-text;
    }
  }
  &.active {
    opacity: 1;
    visibility: visible;
    animation: dropdownMenu .5s;
    z-index: 2;

  }
}


  @media only screen and (max-width: 87.5em) {
    .drop-down-btn{
      .drop-down-icon-btn{
        width: 3.5rem;
        height: 3.5rem;
        .icon{
          width: 4px;
        }
      }
    }
    .drop-down-menu{
      width: 140px;
      padding: 8px 0;
      a,
      button {
        height: 35px;
        font-size: 13px;
      }
    }
}
