@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100%{
    opacity: 1;
    transform: scale(1);

  }
}