.field-set {
  border: none;
  margin: 1.5rem 0;
  label {
    display: block;
    margin-bottom: .8rem;
    font-weight: 600;
  }
  .input-file,
  select,
  input {
    width: 100%;
    line-height: 5.5rem;
    padding: 0 1.5rem;
    background-color: #f9faff;
    border: 1px solid #e0e7ff;
    border-radius: 3px;
    color: #000;
    font-family: inherit;
    font-weight: 500;
    font-size: 1.8rem;
    // &::placeholder {
    //   color: #000;
    // }
    // &:-ms-input-placeholder {
    //   color: #000;
    // }

    // &::-ms-input-placeholder {
    //   color: #000;
    // }
  }
  &.primary {
    .input-file,
    select,
    input {
      line-height: 4.5rem;
      background-color: #f3f3f6;
      border: 1px solid #dadbe3;
    }
    label {
      color: #898989;
    }
  }
  .input-file {
    position: relative;
    input {
      position: relative;
      opacity: 0;
      z-index: 2;
      padding: 0;
      cursor: pointer;
    }
    .place-holder {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 1.5rem;
      color: #848484;
    }
    .upload-icon {
      position: absolute;
      top: .9rem;
      right: 1.5rem;
      width: 2.2rem;
    }
  }

  &.error{
    .input-file,
    select,
    input {
      &, &:focus{
        border-color: #d0021b !important;
      }
    }
  }

  // 1400
  @media only screen and (max-width: 87.5em) {
    margin: 1.2rem 0;
    label {
      font-size: 14px;
      font-weight: 500;
    }
    .input-file,
    input {
      line-height: 4.5rem;
    }
    &.primary {
      select,
      input {
        line-height: 4.2rem;
      }
      .input-file{
        line-height: 4rem;
        .upload-icon{
        width: 2rem;
        }
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #e0e7ff;
  -webkit-text-fill-color: #525050;
  -webkit-box-shadow: 0 0 0 1000px #f9faff inset;
  transition: background-color 5000s ease-in-out 0s;
}

