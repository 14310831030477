.radio-button{
  &{
    border: none;
    margin: 1.5rem 0;
    &__text{
      color: #898989;
      margin-bottom: .8rem;
      font-weight: 600;
    }

    &__container {
      display: inline-flex;
      align-items: center;
      &:not(:last-child){
        margin-right: 1rem;
      }
    }

    &__input {
      display: none;
      &:checked + .radio-button__label {
        .radio-button__label-active{
          display: inline-block;
        }
        .radio-button__label-inactive{
          display: none;
        }
      }
    }

    &__label {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: .7rem;
      img{
        width: 1.8rem;
      }
      &-active{
        display: none;
      }
    }
  }

  @media only screen and (max-width: 87.5em) {
    &{
      &__text {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
