.user-input {
    width: 95%;
    & input:hover {
        cursor: copy;
    }
    &__line {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5.2rem;
        height: 3.5rem;
        border-left: 1.5px solid #e6e6e6;
    }
    &__icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1.2rem;
    }
    &__holder {
        position: absolute;
        transform: translateX(-1000px);
    }
    & .loading {
        -webkit-animation: loading360 1s ease infinite;
        animation: loading360 1s ease infinite;
    }
}

.user-dialog {
    & .field-set {
        input {
            border: 2px solid #c6d3ff;
        }
    }

    & .radio-button__text {
        letter-spacing: 0.2rem;
        text-transform: uppercase;
        color: #000000;
    }

    & .checkbox__box {
        width: 2rem;
        height: 2rem;
        border: 3px solid #000000;

        img {
            width: 100% !important;
            left: 0px;
        }
        &:hover {
            cursor: pointer;
        }
    }

    & label.bold {
        letter-spacing: 0.2rem;
        text-transform: uppercase;
        color: #000000;
    }

    & fieldset.users-checkboxes{
        width: 95%;
    }
}

.select-box {
    &__border {
        border: 2px solid #c6d3ff;
        padding: 0.5rem;
        border-radius: 7px;
    }
}

.user-modal-label {
    display: block;
    margin-bottom: 0.8rem;
    font-weight: 600;
    text-transform: capitalize;
}

.users-checkboxes {
    & .checkbox strong {
        font-weight: 800;
    }
    & .disable{
        opacity: .5;
        pointer-events: none;
    }
    &.chat-checkboxes label.checkbox__label {
        color: rgba(0,0,0,0.85);
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 19px;
    }
    &.chat-checkboxes .checkbox strong {
        font-weight: 700;
    }
}


@-webkit-keyframes loading360 {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes loading360 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
