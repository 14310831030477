$color-1: #d8d8d8;
$color-2: #f0f0f0;
$black: #000000;

.pagination {
  list-style: none;
  .page-item {
    display: inline-block;
    border-top: 1px solid $color-1;
    border-right: 1px solid $color-1;
    border-bottom: 1px solid $color-1;
    &:first-child{
      border-left: 1px solid $color-1;
    }
    .page-link {
      min-width: 32px;
      height: 32px;
      padding: 8px 12px;
      background-color: transparent;
      border: none;
      color: $black;
      font-size: 14px;
      .page-arrow{
        &.rotate{
          transform: rotate(180deg);
        }
      }
      &:disabled {
        cursor: not-allowed;
      }
    }
    &.active,
    &:hover {
      background-color: $color-2;
    }
  }
}
