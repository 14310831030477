@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800,900);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  /*Now convert all px value into rem dependent on root font-size  */
  /*font-size: 10px; */
  /*Set root font-size is 10px because  calculate rem is easy 10px == 1rem*/
  /*default root font size is 16px*/
  font-size: 62.5%;
  /* (10/16) == 0.625 => 0.625*100 == 62.5%*/
}
@media only screen and (max-width: 87.5em) {
  html {
    font-size: 56.25%;
  }
}
@media only screen and (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}

body {
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
}

.container {
  width: 80%;
  max-width: 1600px;
  margin: 0 auto;
}

.container-p-l {
  padding-left: 10%;
}

.container-p-r {
  padding-right: 10%;
}

.container-m-l {
  margin-left: 10%;
}

.container-m-r {
  margin-right: 10%;
}

a, button {
  color: inherit;
  text-decoration: none;
}
a:focus, a:active, button:focus, button:active {
  color: inherit;
  outline: none;
}
a:hover, button:hover {
  cursor: pointer;
}

ul {
  list-style: none;
}
ul li {
  display: inline-block;
}

.m-a {
  margin-left: auto;
  margin-right: auto;
}

.m {
  margin: 3rem;
}

.m-x {
  margin-left: 3rem;
  margin-right: 3rem;
}

.m-y {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.m-l {
  margin-left: 3rem;
}

.m-r {
  margin-right: 3rem;
}

.m-t {
  margin-top: 3rem;
}

.m-b {
  margin-bottom: 3rem;
}

.m-xs {
  margin: 1.5rem;
}

.m-x-xs {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.m-y-xs {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.m-l-xs {
  margin-left: 1.5rem;
}

.m-r-xs {
  margin-right: 1.5rem;
}

.m-t-xs {
  margin-top: 1.5rem;
}

.m-b-xs {
  margin-bottom: 1.5rem !important;
}

.m-sm {
  margin: 4rem;
}

.m-x-sm {
  margin-left: 4rem;
  margin-right: 4rem;
}

.m-y-sm {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.m-l-sm {
  margin-left: 4rem;
}

.m-r-sm {
  margin-right: 4rem;
}

.m-t-sm {
  margin-top: 4rem;
}

.m-b-sm {
  margin-bottom: 4rem;
}

.m-md {
  margin: 6rem;
}

.m-x-md {
  margin-left: 6rem;
  margin-right: 6rem;
}

.m-y-md {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.m-l-md {
  margin-left: 6rem;
}

.m-r-md {
  margin-right: 6rem;
}

.m-t-md {
  margin-top: 6rem;
}

.m-b-md {
  margin-bottom: 6rem;
}

.m-lg {
  margin: 7rem;
}

.m-x-lg {
  margin-left: 7rem;
  margin-right: 7rem;
}

.m-y-lg {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.m-l-lg {
  margin-left: 7rem;
}

.m-r-lg {
  margin-right: 7rem;
}

.m-t-lg {
  margin-top: 7rem;
}

.m-b-lg {
  margin-bottom: 7rem;
}

.m-xlg {
  margin: 10rem;
}

.m-x-xlg {
  margin-left: 10rem;
  margin-right: 10rem;
}

.m-y-xlg {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.m-l-xlg {
  margin-left: 10rem;
}

.m-r-xlg {
  margin-right: 10rem;
}

.m-t-xlg {
  margin-top: 10rem;
}

.m-b-xlg {
  margin-bottom: 10rem;
}

.m-t-42 {
  margin-top: 4.2rem !important;
}

.p {
  padding: 3rem;
}

.p-x {
  padding-left: 3rem;
  padding-right: 3rem;
}

.p-y {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.p-l {
  padding-left: 3rem;
}

.p-r {
  padding-right: 3rem;
}

.p-t {
  padding-top: 3rem;
}

.p-b {
  padding-bottom: 3rem;
}

.p-xs {
  padding: 1.5rem;
}

.p-x-xs {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.p-y-xs {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.p-l-xs {
  padding-left: 1.5rem;
}

.p-r-xs {
  padding-right: 1.5rem;
}

.p-t-xs {
  padding-top: 1.5rem;
}

.p-b-xs {
  padding-bottom: 1.5rem;
}

.p-sm {
  padding: 4rem;
}

.p-x-sm {
  padding-left: 4rem;
  padding-right: 4rem;
}

.p-y-sm {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.p-l-sm {
  padding-left: 4rem;
}

.p-r-sm {
  padding-right: 4rem;
}

.p-t-sm {
  padding-top: 4rem;
}

.p-b-sm {
  padding-bottom: 4rem;
}

.p-md {
  padding: 6rem;
}

.p-x-md {
  padding-left: 6rem;
  padding-right: 6rem;
}

.p-y-md {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.p-l-md {
  padding-left: 6rem;
}

.p-r-md {
  padding-right: 6rem;
}

.p-t-md {
  padding-top: 6rem;
}

.p-b-md {
  padding-bottom: 6rem;
}

.p-lg {
  padding: 7rem;
}

.p-x-lg {
  padding-left: 7rem;
  padding-right: 7rem;
}

.p-y-lg {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.p-l-lg {
  padding-left: 7rem;
}

.p-r-lg {
  padding-right: 7rem;
}

.p-t-lg {
  padding-top: 7rem;
}

.p-b-lg {
  padding-bottom: 7rem;
}

.p-xlg {
  padding: 10rem;
}

.p-x-xlg {
  padding-left: 10rem;
  padding-right: 10rem;
}

.p-y-xlg {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.p-l-xlg {
  padding-left: 10rem;
}

.p-r-xlg {
  padding-right: 10rem;
}

.p-t-xlg {
  padding-top: 10rem;
}

.p-b-xlg {
  padding-bottom: 10rem;
}

.white-box {
  background-color: #ffffff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}

.fancy-scroll {
  overflow-y: auto;
}
.fancy-scroll::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
.fancy-scroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: transparent;
}
.fancy-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: transparent;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.fancy-scroll:hover::-webkit-scrollbar-thumb {
  background-color: rgba(58, 175, 80, 0.8);
}

.table-search {
  padding: 18px 20px;
}

.f-14 {
  font-size: 1.4rem;
}

.f-18 {
  font-size: 1.8rem;
  line-height: 2.8rem;
}

.f-22 {
  font-size: 2.2rem;
  line-height: 3rem;
}

.f-24 {
  font-size: 2.4rem;
  line-height: 3.5rem;
}

.f-45 {
  font-size: 4.5rem;
}

.primary-text {
  color: #3aaf50;
}

.secondary-text {
  color: #223150;
}

.gray-text {
  color: #898989;
}

.hover-primary-text:hover {
  color: #3aaf50;
}

.white-text {
  color: #ffffff;
}

.black-text {
  color: #000000;
}

.danger-text {
  color: #db3c4f;
}

.light-gray-text {
  color: #898989;
}

.center {
  text-align: center;
}

.right {
  text-align: right !important;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.semi-bold {
  font-weight: 700;
}

.bold {
  font-weight: bold;
}

.bolder {
  font-weight: bolder;
}

.a {
  text-decoration: none;
  border-bottom: 1px solid transparent;
}
.a, .a:active, .a:focus {
  color: #64a0e6;
}
.a:hover {
  border-color: #64a0e6;
}
.a.border-none:hover {
  border-color: transparent;
}

.relative {
  position: relative;
}

.filter-gray {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

.rotate-90 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.l-h-3, .article-l-h-3 p {
  line-height: 3rem;
}

.p-18, .article-p-18 p {
  font-size: 1.8rem;
  line-height: 2.4rem;
}

.hidden {
  display: none;
}

.h-100 {
  height: 100vh;
}

.bg-white {
  background-color: #ffffff;
}

.fixed {
  position: fixed !important;
}

.pointer {
  cursor: pointer;
}

.disabled:hover {
  cursor: not-allowed !important;
}

.flex {
  display: flex;
}

.flex-fs {
  display: flex;
  align-items: flex-start;
}

.flex-sb {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.flex-sb-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-sb-end {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.flex-sa {
  display: flex;
  justify-content: space-around;
}

.flex-e {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}

.flex-e-center {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.flex-e-end {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center-start {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-stretch {
  display: flex;
  align-items: stretch;
}

.inline-flex-start {
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.inline-flex-center {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.inline-flex-end {
  display: inline-flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.flex-align-stretch {
  align-items: stretch;
}

.flex-grow {
  flex-grow: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-col {
  flex-direction: column;
}

.align-self-center {
  align-self: center;
}

.flex-20 {
  width: 20%;
}

.flex-30 {
  width: 20%;
}

.flex-40 {
  width: 40%;
}

.flex-45 {
  width: 45%;
}

.flex-50 {
  width: 50%;
}

.flex-55 {
  width: 55%;
}

.flex-60 {
  width: 60%;
}

.flex-70 {
  width: 70%;
}

.flex-80 {
  width: 80%;
}

.flex-100 {
  width: 100%;
}

@media only screen and (max-width: 75em) {
  .md-order-inverse {
    order: 1;
  }

  .md-jc-center {
    justify-content: center;
  }

  .md-flex-col {
    flex-direction: column;
  }

  .md-flex-100 {
    width: 100% !important;
  }

  .md-flex-50 {
    width: 50% !important;
  }

  .md-flex-auto {
    width: auto;
  }
}
@media only screen and (max-width: 56.25em) {
  .sm-order-inverse {
    order: 1;
  }

  .sm-flex-col {
    flex-direction: column;
  }

  .sm-flex-100 {
    width: 100% !important;
  }

  .sm-flex-50 {
    width: 50% !important;
  }

  .sm-full-100 {
    width: 100% !important;
  }
}
@media only screen and (max-width: 37.5em) {
  .xs-order-inverse {
    order: 1;
  }

  .xs-flex-col {
    flex-direction: column;
  }

  .xs-hidden {
    display: none;
  }

  .xs-full-width {
    width: 100% !important;
  }

  .xs-flex-100 {
    width: 100% !important;
  }

  .xs-flex-50 {
    width: 50% !important;
  }

  .xs-jc-start {
    justify-content: flex-start;
  }
}
.primary-heading {
  font-size: 2.8rem;
  position: relative;
  display: inline-block;
  text-transform: uppercase;
}
.primary-heading:before {
  content: "";
  position: absolute;
  top: -15px;
  left: 50%;
  width: 11rem;
  height: 3px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #3aaf50;
}
.primary-heading.white {
  color: #ffffff;
}
.primary-heading.white:before {
  background-color: #ffffff;
}
.primary-heading:hover:before {
  width: 13rem;
}

@media only screen and (max-width: 37.5em) {
  .xs-m-n-l {
    margin-left: 0;
  }

  .xs-m-n-r {
    margin-right: 0;
  }

  .xs-m-n-t {
    margin-top: 0;
  }

  .xs-m-n-b {
    margin-bottom: 0;
  }

  .xs-m-l {
    margin-left: 3rem;
  }

  .xs-m-r {
    margin-right: 3rem;
  }

  .xs-m-t {
    margin-top: 3rem;
  }

  .xs-m-b {
    margin-bottom: 3rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .xs-p-n-l {
    padding-left: 0;
  }

  .xs-p-n-r {
    padding-right: 0;
  }

  .xs-p-n-t {
    padding-top: 0;
  }

  .xs-p-n-b {
    padding-bottom: 0;
  }

  .xs-p-l {
    padding-left: 3rem;
  }

  .xs-p-r {
    padding-right: 3rem;
  }

  .xs-p-t {
    padding-top: 3rem;
  }

  .xs-p-b {
    padding-bottom: 3rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .xs-hidden {
    display: none;
  }

  .xs-visible {
    display: inline;
    display: initial;
  }

  .xs-flex-100 {
    width: 100%;
  }

  .xs-flex-col {
    flex-direction: column;
  }

  .xs-flex-wrap {
    flex-wrap: wrap;
  }

  .xs-align-items-center {
    align-items: center;
  }

  .xs-m-a {
    margin: auto;
  }
}
@media only screen and (max-width: 56.25em) {
  .md-p-n-l {
    padding-left: 0;
  }

  .md-p-n-r {
    padding-right: 0;
  }

  .md-p-n-t {
    padding-top: 0;
  }

  .md-p-n-b {
    padding-bottom: 0;
  }

  .md-p-l {
    padding-left: 3rem;
  }

  .md-p-r {
    padding-right: 3rem;
  }

  .md-p-t {
    padding-top: 3rem;
  }

  .md-p-b {
    padding-bottom: 3rem;
  }
}
@media only screen and (max-width: 56.25em) {
  .sm-hidden {
    display: none;
  }

  .sm-visible {
    display: inline;
    display: initial;
  }

  .sm-flex-100 {
    width: 100%;
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.visibility-none {
  visibility: hidden;
}

.campainAndAgeGated {
  display: flex;
  justify-content: space-between;
}
.account__logo {
  font-size: 0;
  position: absolute;
  top: 5rem;
  left: 5rem;
}
.account__logo img {
  width: 250px;
}
.account__left {
  background-image: url(/static/media/bg.102ba4ae.jpg);
  background-size: cover;
}
.account__right {
  background-color: #fff;
  overflow: hidden;
  overflow-y: auto;
  height: 100vh;
}
.account__form {
  min-height: calc(100vh - 7rem);
}
.account__have {
  height: 7rem;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 5rem;
}
@media only screen and (max-width: 87.5em) {
  .account__logo {
    top: 4rem;
    left: 4rem;
  }
  .account__logo img {
    width: 182px;
  }
  .account__have {
    font-size: 14px;
  }
}
@media only screen and (max-width: 56.25em) {
  .account__have {
    justify-content: space-between;
    height: 7rem;
  }
  .account .account__logo {
    position: relative;
    top: auto;
    top: initial;
    left: auto;
    left: initial;
    max-width: 20rem;
    display: inline-block !important;
  }
}
@media only screen and (max-width: 37.5em) {
  .account__form {
    min-height: calc(100vh - 12rem);
  }
  .account__have {
    justify-content: center;
    height: 12rem;
  }
  .account .account__logo {
    padding: 2rem 0;
  }
}
.form.login, .form.forgot-password {
  padding: 3rem 0;
  width: 85%;
  max-width: 500px;
}
.form__heading {
  font-size: 3.8rem;
  font-weight: 500;
}
.form__sub-heading {
  font-size: 2rem;
}
.form .forgot-link {
  position: absolute;
  top: 1rem;
  right: 0;
}
@media only screen and (max-width: 87.5em) {
  .form.login, .form.forgot-password {
    max-width: 440px;
  }
  .form__heading {
    font-size: 3.5rem;
  }
  .form__sub-heading {
    font-size: 1.6rem;
    font-weight: 400;
  }
  .form .forgot-link {
    top: 0.5rem;
  }
}
.field-set {
  border: none;
  margin: 1.5rem 0;
}
.field-set label {
  display: block;
  margin-bottom: 0.8rem;
  font-weight: 600;
}
.field-set .input-file,
.field-set select,
.field-set input {
  width: 100%;
  line-height: 5.5rem;
  padding: 0 1.5rem;
  background-color: #f9faff;
  border: 1px solid #e0e7ff;
  border-radius: 3px;
  color: #000;
  font-family: inherit;
  font-weight: 500;
  font-size: 1.8rem;
}
.field-set.primary .input-file,
.field-set.primary select,
.field-set.primary input {
  line-height: 4.5rem;
  background-color: #f3f3f6;
  border: 1px solid #dadbe3;
}
.field-set.primary label {
  color: #898989;
}
.field-set .input-file {
  position: relative;
}
.field-set .input-file input {
  position: relative;
  opacity: 0;
  z-index: 2;
  padding: 0;
  cursor: pointer;
}
.field-set .input-file .place-holder {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 1.5rem;
  color: #848484;
}
.field-set .input-file .upload-icon {
  position: absolute;
  top: 0.9rem;
  right: 1.5rem;
  width: 2.2rem;
}
.field-set.error .input-file, .field-set.error .input-file:focus,
.field-set.error select,
.field-set.error select:focus,
.field-set.error input,
.field-set.error input:focus {
  border-color: #d0021b !important;
}
@media only screen and (max-width: 87.5em) {
  .field-set {
    margin: 1.2rem 0;
  }
  .field-set label {
    font-size: 14px;
    font-weight: 500;
  }
  .field-set .input-file,
.field-set input {
    line-height: 4.5rem;
  }
  .field-set.primary select,
.field-set.primary input {
    line-height: 4.2rem;
  }
  .field-set.primary .input-file {
    line-height: 4rem;
  }
  .field-set.primary .input-file .upload-icon {
    width: 2rem;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #e0e7ff;
  -webkit-text-fill-color: #525050;
  -webkit-box-shadow: 0 0 0 1000px #f9faff inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}
.radio-button {
  border: none;
  margin: 1.5rem 0;
}
.radio-button__text {
  color: #898989;
  margin-bottom: 0.8rem;
  font-weight: 600;
}
.radio-button__container {
  display: inline-flex;
  align-items: center;
}
.radio-button__container:not(:last-child) {
  margin-right: 1rem;
}
.radio-button__input {
  display: none;
}
.radio-button__input:checked + .radio-button__label .radio-button__label-active {
  display: inline-block;
}
.radio-button__input:checked + .radio-button__label .radio-button__label-inactive {
  display: none;
}
.radio-button__label {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.7rem;
}
.radio-button__label img {
  width: 1.8rem;
}
.radio-button__label-active {
  display: none;
}
@media only screen and (max-width: 87.5em) {
  .radio-button__text {
    font-size: 14px;
    font-weight: 500;
  }
}
.search-field fieldset {
  border: none;
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 350px;
}
.search-field fieldset .search-icon {
  position: absolute;
  top: 10px;
  left: 13px;
  width: 16px;
}
.search-field fieldset .search-icon img {
  width: 100%;
}
.search-field fieldset input {
  width: 100%;
  max-width: 350px;
  padding: 0 15px 0 40px;
  color: #000000;
  border: 1px solid #898989;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  height: 38px;
  font-family: inherit;
}
.search-field fieldset input:focus {
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.25);
}
.search-field fieldset input::-webkit-input-placeholder {
  color: #898989;
}
.search-field fieldset input::-moz-placeholder {
  color: #898989;
}
.search-field fieldset input:-ms-input-placeholder {
  color: #898989;
}
.search-field fieldset input::-ms-input-placeholder {
  color: #898989;
}
.search-field fieldset input::placeholder {
  color: #898989;
}
.search-field fieldset input:-ms-input-placeholder {
  color: #898989;
}
.search-field fieldset input::-ms-input-placeholder {
  color: #898989;
}
.search-field fieldset.event-search {
  max-width: 255px;
}
.search-field fieldset.event-search input {
  max-width: 255px;
  border: 1px solid #e8ebf4;
  box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.2);
  font-size: 12px;
}
.search-field fieldset.event-search input::-webkit-input-placeholder {
  color: #898989;
}
.search-field fieldset.event-search input::-moz-placeholder {
  color: #898989;
}
.search-field fieldset.event-search input:-ms-input-placeholder {
  color: #898989;
}
.search-field fieldset.event-search input::-ms-input-placeholder {
  color: #898989;
}
.search-field fieldset.event-search input::placeholder {
  color: #898989;
}
.search-field fieldset.event-search input:-ms-input-placeholder {
  color: #898989;
}
.search-field fieldset.event-search input::-ms-input-placeholder {
  color: #898989;
}
@media only screen and (max-width: 56.25em) {
  .search-field fieldset {
    max-width: 100% !important;
  }
  .search-field fieldset input {
    max-width: 100% !important;
  }
}
.type-head {
  border: none;
  margin: 1.5rem 0;
}
@media only screen and (max-width: 87.5em) {
  .type-head .css-2o5izw,
.type-head .css-vj8t7z {
    line-height: 3rem;
  }
}
.checkbox {
  display: inline-flex;
  align-items: center;
  z-index: 1;
}
.checkbox__input {
  display: none;
}
.checkbox__box {
  width: 2.5rem;
  height: 2.5rem;
  border: 2px solid #000000;
  background-color: transparent;
  display: inline-block;
  border-radius: 3px;
  position: relative;
}
.checkbox__box .icon-check {
  width: 1.8rem !important;
  position: absolute;
  top: 2px;
  left: 2px;
  visibility: hidden;
}
.checkbox__label {
  margin-left: 1rem;
}
.checkbox.white .checkbox__box {
  border: 2px solid #fff;
}
.checkbox .checkbox__input:checked + .checkbox__box {
  background-color: #3aaf50;
  border-color: #3aaf50;
}
.checkbox .checkbox__input:checked + .checkbox__box .icon-check {
  visibility: visible;
}
.spinner-loading {
  display: block;
  height: 32px;
  width: 32px;
  -webkit-animation: animated360 3s linear infinite;
  animation: animated360 3s linear infinite;
}
.spinner-loading span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 32px;
  width: 32px;
  clip: rect(16px, 32px, 32px, 0);
  -webkit-animation: animated360 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: animated360 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
.spinner-loading span:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 32px;
  width: 32px;
  border: 3px solid transparent;
  border-top: 3px solid #ffffff;
  border-radius: 50%;
  -webkit-animation: animated360 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: animated360 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
.spinner-loading span:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 32px;
  width: 32px;
  border: 3px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
}
.spinner-loading.green span:before {
  border-top: 3px solid #3aaf50;
}
.spinner-loading.green span:after {
  border: 3px solid rgba(58, 175, 80, 0.8);
}
.spinner-loading.black span:before {
  border-top: 3px solid #000000;
}
.spinner-loading.black span:after {
  border: 3px solid rgba(0, 0, 0, 0.5);
}
.spinner-loading.small {
  height: 20px;
  width: 20px;
}
.spinner-loading.small span {
  height: 20px;
  width: 20px;
  clip: rect(10px, 20px, 20px, 0);
}
.spinner-loading.small span:before, .spinner-loading.small span:after {
  height: 20px;
  width: 20px;
}
.spinner-loading.large {
  height: 50px;
  width: 50px;
}
.spinner-loading.large span {
  height: 50px;
  width: 50px;
  clip: rect(25px, 50px, 50px, 0);
}
.spinner-loading.large span:before, .spinner-loading.large span:after {
  height: 50px;
  width: 50px;
}

@-webkit-keyframes animated360 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes animated360 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.layout-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3) !important;
  top: 0;
  left: 0;
  z-index: 999;
}
.file-uploader {
    margin-bottom: 1rem;
}

.file-uploader-main {
    width: 120px;
    height: 120px;
    border-style: solid;
    border-color: #d3d3d3;
    border-width: 2.5px;
    border-radius: 10px;
    position: relative;
}

.file-uploader-main.overlay {
    border-style: dashed;
}

.file-uploader-overlay i{
    font-size: 20px;
    position: relative;
    left: 5px;
}


.file-uploader-input {
    width: 100%;
    height: 100%;
}

.file-uploader-input input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    z-index: 2;
}


.file-uploader-input input:hover {
    cursor: pointer;
}

.file-uploader-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #dad8d845;
    color: #3e3e3e94;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
}


.file-uploader-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    height: 100%;
}

.file-uploader-text {
    width: 90%;
    text-align: center;
    font-size: 1rem;
    color: #5b5b5b;
}

.file-uploader-loading {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.file-uploader-loading .progress {
    width: 80%;
}

.file-uploader-cancel {
    display: none;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
}

.file-uploader-cancel:hover {
    cursor: pointer;
    background-color: #3532326e;
}

.file-uploader-cancel i {
    font-size: 45px;
    color: #f2f1f0eb;
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
}

.file-uploader-cancel:hover i {
    color: #e1ded9eb;
}

.file-uploader-main:hover .file-uploader-cancel{
    display: flex;
}
.custom-toast-container .custom-toast-message {
  padding: 1.3125em 2.3125em;
  min-height: 6.8125em;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.5);
}

.custom-toast-message.success-message {
  background-color: #3aaf50 !important;
}

.custom-toast-message.error-message {
  background-color: #e74c3c !important;
}

.custom-toast-container .toaster-message-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.custom-toast-container .toaster-text {
  font-family: "Montserrat";
  font-size: 2.4rem;
  line-height: 1.8125em;
  font-weight: 500;
  flex-basis: 90%;
  margin-left: 19px;
  color: white;
}

.message-icon-wrapper {
  position: relative;
  width: 3.125em;
  height: 3.125em;
  flex-basis: 10%;
  box-sizing: content-box;
  box-sizing: initial;
}

.success-mark,
.close-mark {
  position: absolute;
  background-color: white;
  border-radius: 50%;
  height: 3.125em;
  width: 3.125em;
}

.success-mark:after {
  content: "";
  position: absolute;
  display: block;
  left: 20px;
  top: 10px;
  width: 11px;
  height: 25px;
  border: solid #3aaf50;
  border-width: 0 4px 4px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.close-mark:before,
.close-mark:after {
  position: absolute;
  left: 26px;
  top: 14px;
  content: " ";
  height: 29px;
  width: 4px;
  background-color: #e74c3c;
}

.close-mark:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.close-mark:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.secondary-btn,
.primary-btn {
  min-width: 17rem;
  border: 1px solid #898989;
  border-radius: 3px;
  color: #ffffff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  font-size: 1.8rem;
  font-family: inherit;
  font-weight: bold;
  text-transform: capitalize;
  background-color: #898989;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  box-shadow: -2.5px 4px 9px -5px rgba(0, 0, 0, 0.6);
}
.secondary-btn .icon,
.primary-btn .icon {
  max-width: 3.5rem;
  margin-right: 1.2rem;
}
.secondary-btn .icon img,
.primary-btn .icon img {
  width: 100%;
}
.secondary-btn.file-btn,
.primary-btn.file-btn {
  position: relative;
}
.secondary-btn.file-btn input,
.primary-btn.file-btn input {
  width: 100%;
  position: absolute;
  height: 100%;
  opacity: 0;
}
.secondary-btn.out-line,
.primary-btn.out-line {
  background-color: transparent !important;
  color: inherit;
}
.secondary-btn.out-line:active, .secondary-btn.out-line:focus,
.primary-btn.out-line:active,
.primary-btn.out-line:focus {
  color: inherit;
}
.secondary-btn.big,
.primary-btn.big {
  border: 2px solid #898989;
  font-size: 2.4rem;
  padding: 14px 18px;
  min-width: 21rem;
}
.secondary-btn.green-gradient,
.primary-btn.green-gradient {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#3aaf50), to(#26651e));
  background-image: linear-gradient(#3aaf50, #26651e);
  border: none;
  box-shadow: -5px 6px 13px -5px rgba(0, 0, 0, 0.69);
}
.secondary-btn.green,
.primary-btn.green {
  border: 1px solid #3aaf50;
  background-color: #3aaf50;
}
.secondary-btn.green.out-line,
.primary-btn.green.out-line {
  color: #3aaf50;
}
.secondary-btn.green.big,
.primary-btn.green.big {
  border: 2px solid #3aaf50;
}
.secondary-btn.purple,
.primary-btn.purple {
  border: 1px solid #223150;
  background-color: #223150;
}
.secondary-btn.purple.out-line,
.primary-btn.purple.out-line {
  color: #223150;
}
.secondary-btn.purple.big,
.primary-btn.purple.big {
  border: 2px solid #223150;
}
.secondary-btn.red,
.primary-btn.red {
  border: 1px solid #d0021b;
  background-color: #d0021b;
}
.secondary-btn.red.out-line,
.primary-btn.red.out-line {
  color: #d0021b;
}
.secondary-btn.red.big,
.primary-btn.red.big {
  border: 2px solid #d0021b;
}
.secondary-btn.right-icon .icon,
.primary-btn.right-icon .icon {
  order: 1;
  margin-left: 1.2rem;
  margin-right: 0;
}
.secondary-btn.spacing,
.primary-btn.spacing {
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.secondary-btn:active, .secondary-btn:focus,
.primary-btn:active,
.primary-btn:focus {
  color: #fff;
}
@media only screen and (max-width: 87.5em) {
  .secondary-btn,
.primary-btn {
    min-width: 11.2rem;
    padding: 0 10px;
    border-radius: 2px;
    height: 38px;
    font-size: 14px;
  }
  .secondary-btn .icon,
.primary-btn .icon {
    max-width: 2.5rem;
    margin-right: 1rem;
  }
  .secondary-btn.medium,
.primary-btn.medium {
    height: 42px;
    padding: 0 18px;
    font-size: 15px;
  }
  .secondary-btn.big,
.primary-btn.big {
    height: 55px;
    padding: 0 18px;
    border-radius: 5px;
    font-size: 17px;
  }
}
.icon-btn {
  background-color: #cdcdcd;
  width: 3rem;
  height: 3rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
}
.icon-btn .icon {
  max-width: 1.5rem;
}
.icon-btn.transparent {
  background-color: transparent;
}
.icon-btn.green {
  background-color: #3aaf50;
}
.icon-btn.red {
  background-color: #d0021b;
}
.icon-btn.white {
  background-color: #fff;
}
.icon-btn.transparent {
  background-color: transparent;
}
@media only screen and (max-width: 87.5em) {
  .icon-btn {
    width: 3.3rem;
    height: 3.3rem;
  }
  .icon-btn .icon {
    max-width: 1.7rem;
  }
}
.drop-down-menu {
  position: absolute;
  width: 180px;
  padding: 15px 0;
  top: 10px;
  right: 0;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.drop-down-menu a,
.drop-down-menu button {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 15px 0 15px;
  border: none;
  background-color: transparent;
  font-size: 15px;
  position: relative;
  font-family: inherit;
}
.drop-down-menu a .svg-icon,
.drop-down-menu button .svg-icon {
  position: absolute;
  left: 15px;
}
.drop-down-menu a .icon,
.drop-down-menu button .icon {
  padding-right: 40px;
}
.drop-down-menu a:hover,
.drop-down-menu button:hover {
  background-color: #c3e7ca;
}
.drop-down-menu.active {
  opacity: 1;
  visibility: visible;
  -webkit-animation: dropdownMenu 0.5s;
          animation: dropdownMenu 0.5s;
  z-index: 2;
}

@media only screen and (max-width: 87.5em) {
  .drop-down-btn .drop-down-icon-btn {
    width: 3.5rem;
    height: 3.5rem;
  }
  .drop-down-btn .drop-down-icon-btn .icon {
    width: 4px;
  }

  .drop-down-menu {
    width: 140px;
    padding: 8px 0;
  }
  .drop-down-menu a,
.drop-down-menu button {
    height: 35px;
    font-size: 13px;
  }
}
.rb-dialog {
  width: 100%;
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.7);
}
.rb-dialog__content {
  width: 90%;
  max-width: 75rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.rb-dialog__body {
  width: 100%;
  min-height: 200px;
  border-radius: 5px;
  padding: 4rem;
  background-color: #fff;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
}
.rb-dialog.m-w-948 .rb-dialog__content {
  max-width: 94.8rem;
  top: 20%;
  -webkit-transform: translate(-50%, -20%);
          transform: translate(-50%, -20%);
}
.rb-dialog.active {
  opacity: 1;
  visibility: visible;
}
.rb-dialog.active .rb-dialog__body {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.rb-dialog.dialog-modal .rb-dialog__body {
  padding-right: 0;
}
.rb-dialog.dialog-modal .dialog-modal__container {
  max-height: 85vh;
}
.rb-dialog.dialog-modal .close-dialog {
  position: absolute;
  top: 10px;
  right: 10px;
}
.rb-dialog.dialog-modal .close-dialog img {
  width: 2.5rem;
}
.rb-dialog.scrolling .rb-dialog__body {
  padding: 30px 0 20px 20px;
}
.rb-dialog.scrolling .dialog-modal__container {
  padding-right: 20px;
  overflow-x: hidden;
  overflow-y: auto;
}
.rb-dialog.scrolling .dialog-modal__container::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
.rb-dialog.scrolling .dialog-modal__container::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}
.rb-dialog.scrolling .dialog-modal__container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: transparent;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.rb-dialog.scrolling .dialog-modal__container:hover::-webkit-scrollbar-thumb {
  background-color: rgba(58, 175, 80, 0.8);
}

.rb-dialog__body-content-icon {
  width: 100%;
  max-width: 110px;
  max-height: 110px;
  margin-bottom: 20px;
  min-height: 100px;
}
.rb-dialog__body-content-title {
  font-size: 2.5rem;
}

.impersonate-dialog__image {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}
.impersonate-dialog__text {
  display: flex;
  justify-content: center;
  font-size: 4rem;
  margin-bottom: 2rem;
}
.impersonate-dialog__section {
  display: flex;
  justify-content: space-evenly;
  width: 90%;
  margin: 0 auto;
}
.impersonate-dialog__section-btn {
  width: 22.3rem;
  height: 15rem;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 10px;
}
.impersonate-dialog__section-btn:hover {
  cursor: pointer;
}
.impersonate-dialog__section-btn .btn-img {
  width: 14.2rem;
  margin-bottom: 0.4rem;
}
.impersonate-dialog__section-btn .btn-img img {
  width: 100%;
}
.impersonate-dialog__section-btn p {
  font-size: 2rem;
}
.impersonate-dialog__section-btn p strong {
  font-weight: 800;
  text-transform: uppercase;
}

.impersonate .rb-dialog__body {
  padding-right: 4rem !important;
}
.impersonate.rb-dialog .rb-dialog__content {
  top: 20%;
  -webkit-transform: translate(-50%, -20%);
          transform: translate(-50%, -20%);
}

@media only screen and (max-width: 87.5em) {
  .rb-dialog.dialog-modal .close-dialog img {
    width: 2rem;
  }
}
.user-input {
  width: 95%;
}
.user-input input:hover {
  cursor: copy;
}
.user-input__line {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 5.2rem;
  height: 3.5rem;
  border-left: 1.5px solid #e6e6e6;
}
.user-input__icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 1.2rem;
}
.user-input__holder {
  position: absolute;
  -webkit-transform: translateX(-1000px);
          transform: translateX(-1000px);
}
.user-input .loading {
  -webkit-animation: loading360 1s ease infinite;
  animation: loading360 1s ease infinite;
}

.user-dialog .field-set input {
  border: 2px solid #c6d3ff;
}
.user-dialog .radio-button__text {
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  color: #000000;
}
.user-dialog .checkbox__box {
  width: 2rem;
  height: 2rem;
  border: 3px solid #000000;
}
.user-dialog .checkbox__box img {
  width: 100% !important;
  left: 0px;
}
.user-dialog .checkbox__box:hover {
  cursor: pointer;
}
.user-dialog label.bold {
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  color: #000000;
}
.user-dialog fieldset.users-checkboxes {
  width: 95%;
}

.select-box__border {
  border: 2px solid #c6d3ff;
  padding: 0.5rem;
  border-radius: 7px;
}

.user-modal-label {
  display: block;
  margin-bottom: 0.8rem;
  font-weight: 600;
  text-transform: capitalize;
}

.users-checkboxes .checkbox strong {
  font-weight: 800;
}
.users-checkboxes .disable {
  opacity: 0.5;
  pointer-events: none;
}
.users-checkboxes.chat-checkboxes label.checkbox__label {
  color: rgba(0, 0, 0, 0.85);
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
}
.users-checkboxes.chat-checkboxes .checkbox strong {
  font-weight: 700;
}

@-webkit-keyframes loading360 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes loading360 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.table-section {
  overflow: hidden;
  overflow-x: auto;
}
.table-section table {
  border-collapse: collapse;
}
.table-section table .checkbox-col {
  width: 100px;
  text-align: center;
}
.table-section table tr th, .table-section table tr td {
  padding: 2rem;
  text-align: left;
}
.table-section table tr th:first-child, .table-section table tr td:first-child {
  padding-left: 3rem;
}
.table-section table thead {
  background-color: #223150;
}
.table-section table thead tr th, .table-section table thead tr td {
  text-transform: uppercase;
  color: #fff;
}
.table-section table thead tr th .icon-btn, .table-section table thead tr td .icon-btn {
  background-color: transparent;
  width: 2.2rem;
  height: 2.2rem;
  position: relative;
  top: 3px;
}
.table-section table thead tr th .icon-btn img, .table-section table thead tr td .icon-btn img {
  width: 2rem;
}
.table-section table tbody tr {
  background-color: #fff;
}
.table-section table tbody tr:nth-child(even) {
  background-color: #f3f3f3;
}
.table-section table tbody tr .no-data {
  padding: 8rem 0;
  text-align: center;
}
.table-section.infinite-scroll {
  max-height: 500px;
  overflow: auto;
}
.table-section.infinite-scroll thead tr th, .table-section.infinite-scroll thead tr td {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #223150;
}
.table-section.no-scroll {
  overflow: visible;
  overflow: initial;
  overflow-y: inherit;
}
@media only screen and (max-width: 87.5em) {
  .table-section table thead tr th, .table-section table thead tr td {
    font-size: 18px;
  }
  .table-section table tr th, .table-section table tr td {
    padding: 14px;
    font-size: 16px;
  }
  .table-section table tr th:first-child, .table-section table tr td:first-child {
    padding-left: 38px;
  }
}
@media only screen and (max-width: 1000px) {
  .table-section table {
    table-layout: auto;
    table-layout: initial;
  }
}
@media only screen and (max-width: 56.25em) {
  .table-section {
    overflow-x: auto !important;
  }
  .table-section table thead tr th, .table-section table thead tr td {
    font-size: 16px;
  }
  .table-section table tr th, .table-section table tr td {
    padding: 10px 15px;
    font-size: 15px;
  }
  .table-section table tr th:first-child, .table-section table tr td:first-child {
    padding-left: 10px;
  }
}
.pagination {
  list-style: none;
}
.pagination .page-item {
  display: inline-block;
  border-top: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
}
.pagination .page-item:first-child {
  border-left: 1px solid #d8d8d8;
}
.pagination .page-item .page-link {
  min-width: 32px;
  height: 32px;
  padding: 8px 12px;
  background-color: transparent;
  border: none;
  color: #000000;
  font-size: 14px;
}
.pagination .page-item .page-link .page-arrow.rotate {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.pagination .page-item .page-link:disabled {
  cursor: not-allowed;
}
.pagination .page-item.active, .pagination .page-item:hover {
  background-color: #f0f0f0;
}
.type-head {
  border: none;
  margin: 1.5rem 0;
}
@media only screen and (max-width: 87.5em) {
  .type-head .css-2o5izw,
.type-head .css-vj8t7z {
    line-height: 3rem;
  }
}
.companies-input {
  width: 95%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.companies-input.first {
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.companies-input.profile-container {
  flex-direction: row;
}
.companies-input.phone-status {
  display: flex;
  flex-direction: row;
}
.companies-input.phone-status .phone-status__text {
  font-weight: 500;
}
.companies-input.phone-status .phone-status__text span {
  font-weight: 600;
  font-size: 1.6rem;
}
.companies-input.disable fieldset {
  opacity: 0.5;
  pointer-events: none;
}
.companies-input__fieldset {
  margin: 0;
}
.companies-input__checkbox {
  display: flex;
  flex-direction: column;
}
.companies-input__checkbox strong {
  font-weight: 800;
}
.companies-input .field-set.gray input:focus {
  outline: none;
}
.companies-input__icon-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 4rem;
  height: 100%;
  background-color: #223150;
  display: flex;
  justify-content: center;
  align-items: center;
}
.companies-input__icon-container.gray {
  background-color: #898989;
  pointer-events: none;
}
.companies-input__icon-container:hover {
  cursor: pointer;
}
.companies-input__icon-container img {
  width: 2rem;
}
.companies-input__icon-container.loading img {
  -webkit-animation: loading360 1s ease infinite;
  animation: loading360 1s ease infinite;
}
.companies-input__icon-container.icon-del {
  display: none;
}
.companies-input__cross {
  position: absolute;
  right: 8rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.companies-input__cross:hover {
  cursor: pointer;
}
.companies-input .company-logo {
  padding-left: 2.3rem;
}

#segment input {
  line-height: 4.5rem;
}

@-webkit-keyframes loading360 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes loading360 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.logo-user-sec {
  width: 20%;
}

.form-col {
  width: 80%;
}

.form-sec .form-field-sec {
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 40px;
}
.form-sec .icon-del {
  display: none;
}
.form-sec .align-f-end {
  align-items: flex-end;
}
.form-sec fieldset {
  margin: 0px !important;
}
.form-sec label {
  color: #1d3152 !important;
  font-family: Montserrat;
  font-size: 12px !important;
  font-weight: 500;
  letter-spacing: 0 !important;
  line-height: 16px;
  margin-bottom: 5px;
}
.form-sec .field-set .input-file, .form-sec .field-set select, .form-sec .field-set input {
  color: #1d3152;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  border: 1px solid #a7b9c5 !important;
  border-radius: 4px;
  line-height: 37px;
  background-color: #fff;
  padding: 0px 10px;
}
.form-sec .error .input-file, .form-sec .error .input-file:focus,
.form-sec .error select,
.form-sec .error select:focus,
.form-sec .error input,
.form-sec .error input:focus {
  border-color: #d0021b !important;
}
.form-sec .select-box__border {
  border: 1px solid #a7b9c5;
  border-radius: 4px;
  padding: 1px;
}
.form-sec .radio-checkbox-row .companies-input {
  width: 165px !important;
  margin: 0;
}
.form-sec .radio-checkbox-row {
  justify-content: flex-start;
  align-items: flex-start;
}
.form-sec .radio-checkbox-row .companies-input:nth-child(2) {
  width: 265px !important;
}
.form-sec .radio-checkbox-row .companies-input:nth-child(3) {
  width: 345px !important;
}
.form-sec .act-type-btn .m-t-xs {
  margin-top: 0px;
}
.form-sec #segment input {
  border: 0px !important;
}
.form-sec .file-uploader-main {
  width: 150px;
  height: 150px;
}
.form-sec .gray-text {
  color: #a7b9c5 !important;
  font-family: Montserrat;
  font-size: 14px !important;
  font-weight: 600;
  letter-spacing: 0 !important;
  line-height: 16px;
}
.form-sec .users-sec ul li {
  display: block;
  color: #1d3152;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 10px;
}
.form-sec .logo-user-sec button {
  height: 30.5px !important;
  border-radius: 3px;
  box-shadow: none;
}
.form-sec .logo-user-sec button span {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
}
.form-sec .users-sec strong {
  display: block;
  margin-bottom: 10px;
}
.form-sec .users-sec ul {
  margin-bottom: 22px;
  max-height: 500px;
  overflow: auto;
}
.form-sec .logo-col {
  margin-bottom: 20px !important;
}
.form-sec .company-row label {
  margin-bottom: 10px;
}
.form-sec .company-row h2 {
  color: #1d3152;
  font-family: Montserrat;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 34px;
  padding: 0;
  height: auto !important;
  border: 0px !important;
}
.form-sec .company-row {
  align-items: stretch;
  flex-direction: row;
  margin-bottom: 20px;
}
.form-sec .company-row .companyTitle {
  position: relative;
}
.form-sec .company-row .companyTitle input {
  color: #1d3152;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  padding-left: 0;
  outline: none;
  border: none !important;
  padding-right: 25px;
}
.form-sec .company-row .companyTitle::after {
  content: "";
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAXgAAAF4B+NkP+QAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAADMSURBVDiNzdKvTkJRHAfwj9ykDAsPIC/gLAZHpElhOEexOV+DxGAM5yM4GIFAgSdgyiM4OkGDGpwPQDDce0cCzml+yzknfPb7s5OIyzUa+MQvFCLwAPf4wRzVmMoDfOEhe5/hDZUkAD+ijDp6SPCCDc5D8LPtqEUssk5mqMXgPCV8o7sP93bgAkbSnfxD3MFwBx46MHMRH1hKlxSFoYV+di5xGtp2nikus/sdXjEOqQwn0m95hCs84R3tEAy3WGOFCZo4DsVwgZtYlOcPFXcqcrMMBGYAAAAASUVORK5CYII=) no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
  position: absolute;
  pointer-events: none;
  right: 0;
  top: 6px;
}
.form-sec .company-row .companyTitle > input:focus::after {
  opacity: 0;
}
.form-sec .company-row .camp-age {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.form-sec .company-row .camp-age:last-child {
  justify-content: flex-end;
  margin-bottom: 4px;
  padding-left: 60px;
}
.form-sec .company-row .camp-age strong {
  font-weight: 600;
  font-size: 16px;
}
.form-sec .company-row svg {
  font-size: 20px;
}
.form-sec .sec-label {
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
}
.form-sec .checkbox__box {
  width: 18px;
  height: 18px;
  border: 2px solid #1d3152;
  border-radius: 2px !important;
  margin-right: 4px;
}
.form-sec .checkbox label .sec-checkboc {
  color: #1d3152;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
}
.form-sec .webChatSection {
  position: relative;
}
.form-sec .webChatSection .spinner-loading {
  position: absolute;
  left: 120px;
}
.form-sec .club-web-chat-sec label,
.form-sec .companies-input p {
  color: #1d3152 !important;
  font-family: Montserrat;
  font-size: 10px !important;
  font-weight: 500;
  letter-spacing: 0 !important;
  line-height: 18px;
  text-transform: uppercase;
}
.form-sec .club-web-chat-sec .column-3:not(:last-child) {
  margin-right: 2%;
}
.form-sec .club-web-chat-sec .column-3 {
  flex: 0 0 23%;
  max-width: 23%;
}
.form-sec .club-web-chat-sec {
  padding-left: 32px;
  margin-top: 15px;
}
.form-sec .number-row {
  margin-bottom: 30px;
}
.form-sec .club-web-chat-sec .companies-input label {
  margin-right: 0;
}
.form-sec .club-web-chat-sec .companies-input label {
  margin-right: 3px;
  text-transform: capitalize;
}
.form-sec .companies-input p {
  margin-bottom: 5px !important;
}
.form-sec .disable fieldset, .form-sec .disable a, .form-sec .disable .add-websie-btn {
  opacity: 0.5;
  pointer-events: none;
}
.form-sec .segment-sec .basic-multi-select .select__control .select__value-container--is-multi {
  border: 1px solid #a7b9c5;
  border-radius: 4px;
  min-height: 100px;
  align-items: flex-start;
  padding-top: 5px;
}
.form-sec .segment-sec .select-box__border {
  border: 0;
}
.form-sec .segment-sec {
  margin-top: 20px;
  margin-bottom: 25px;
}
.form-sec .segment-sec .companies-input {
  width: 47%;
  margin-right: 6%;
}
.form-sec .web-chat-sec {
  align-items: flex-end;
}
.form-sec .web-chat-sec .column-4 {
  padding-right: 15px;
}
.form-sec .webchat-checkbox .companies-input {
  width: 100%;
}
.form-sec .webchat-item {
  width: 600px;
}
.form-sec .webchat-item:not(:last-child) {
  border-bottom: 1px solid #00000017;
  margin-bottom: 15px;
  padding-bottom: 10px;
}
.form-sec .webchat-item:only-child .deleteWebsiteBtn {
  display: none;
}
.form-sec .webchat-add {
  padding-left: 32px;
  margin-top: 15px;
}
.form-sec .web-chat-sec a .copy-code {
  border-radius: 3px;
  background-color: #1d3152;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  padding: 8px 18px;
  display: table;
}
.form-sec .web-chat-sec span.copied-url {
  border: 1px solid #007fed;
  border-radius: 3px;
  background-color: rgba(0, 127, 237, 0.1);
  color: #1d3152;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  padding: 4px 18px;
  display: table;
  margin-bottom: 3px;
}
.form-sec button.add-websie-btn {
  border-radius: 3px;
  background-color: #3aaf50;
  border: 0px;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 8px 11px;
  margin-bottom: 30px;
}
.form-sec button.add-websie-btn svg {
  color: #fff;
  font-size: 15px;
  margin-right: 8px;
}
.form-sec .clubbooth-sec {
  width: 100%;
}
.form-sec .clubbooth-sec .companies-input {
  width: 100%;
}
.form-sec fieldset.radio-button svg, .form-sec .icon-check svg {
  font-size: 14px;
  margin-top: 5px;
}
.form-sec .radio-button__label-active svg, .form-sec .icon-check svg {
  color: #246b47 !important;
}
.form-sec .radio-button__label-inactive svg {
  color: #748997 !important;
}
.form-sec .check-row .checkbox__box {
  width: 12px;
  height: 12px;
  border: 1.9px solid #748997;
  border-radius: 50px !important;
  margin-right: 4px;
}
.form-sec .check-row .checkbox__box .icon-check {
  width: 14px !important;
  height: 14px;
  position: static;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-sec .check-row label.checkbox__box svg {
  margin: 0px !important;
}
.form-sec .check-row .checkbox .checkbox__box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-sec .check-row .checkbox .checkbox__input:checked + .checkbox__box {
  background-color: transparent;
  border: 0;
}
.form-sec .check-row .companies-input__checkbox {
  margin-top: 8px;
}
.form-sec .comp-form-header h2 {
  color: #1d3152;
  font-family: Montserrat;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 34px;
  margin: 0px !important;
}
.form-sec .comp-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.form-sec .comp-form-header .cancel-btn {
  margin-right: 15px;
}
.form-sec .comp-form-header .cancel-btn:hover {
  background-color: #d0021b;
}
.form-sec .comp-form-header button {
  border-radius: 3px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: #1d3152;
  border-color: #1d3152;
  padding: 8px 16px;
  height: auto !important;
  min-width: auto;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  box-shadow: none;
}
.form-sec .comp-form-header button:hover {
  border-color: #3aaf50;
  background-color: #3aaf50;
}
.form-sec .comp-form-header button.disabled {
  opacity: 0.4;
}
.form-sec .web-chat-sec {
  margin-bottom: 12px;
}
@media only screen and (min-width: 1600px) {
  .form-sec .company-row h2 {
    font-size: 28px;
  }
  .form-sec .club-web-chat-sec label, .form-sec .companies-input p {
    font-size: 14px !important;
  }
}

.d-flex-row {
  display: flex;
  align-items: flex-start;
}

.column-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.column-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.column-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.column-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.column-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.column-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.column-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.column-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.column-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.column-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.column-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.column-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.sidebar {
  width: 300px;
  min-width: 300px;
  z-index: 99;
  height: 100vh;
}
.sidebar-fixed-container {
  position: fixed;
  width: inherit;
  height: 100vh;
  overflow-y: auto;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#223150), to(#071534f0));
  background-image: linear-gradient(#223150, #071534f0);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sidebar-btn {
  display: flex;
  margin-bottom: 3rem;
  margin-left: 2.7rem;
  font-size: 2rem;
  text-transform: uppercase;
  color: #d0021b;
}
.sidebar-btn .icon {
  margin-right: 1rem;
}
.sidebar-btn .text {
  font-weight: 700;
  -webkit-transform: translateY(7px);
          transform: translateY(7px);
}
.sidebar-btn:hover {
  cursor: pointer;
}
.sidebar .create-event-btn {
  margin-top: 50px;
  margin-bottom: 50px;
}
.sidebar .create-event-btn button {
  font-size: 2rem;
}
.sidebar nav ul li {
  display: block;
}
.sidebar nav ul li a {
  color: #fff;
  height: 80px;
  padding-left: 40px;
  position: relative;
  font-size: 2rem;
}
.sidebar nav ul li a .icon {
  display: inline-block;
  margin-right: 25px;
  width: 3.4rem;
}
.sidebar nav ul li a .icon.hover-icon {
  display: none;
}
.sidebar nav ul li a:before {
  content: "";
  width: 5px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.sidebar nav ul li a.active {
  background-color: #ffffff;
  color: #223150;
}
.sidebar nav ul li a.active:before {
  background-color: #3aaf50;
}
.sidebar nav ul li a.active .icon.default-icon {
  display: none;
}
.sidebar nav ul li a.active .icon.hover-icon {
  display: inline-block;
}
@media only screen and (max-width: 87.5em) {
  .sidebar {
    width: 240px;
    min-width: 240px;
  }
  .sidebar nav ul li a {
    color: #fff;
    height: 7rem;
    padding-left: 2.5rem;
  }
  .sidebar nav ul li a .icon {
    margin-right: 1.5rem;
    width: 2.5rem;
  }
}
.dashboard .main-content {
  background-color: #f4f6fc;
  min-height: 100vh;
  padding: 5rem;
  overflow: hidden;
}
.dashboard .sidebar-toggle-btn {
  position: fixed;
  height: 50px;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  background: #223150;
}
.dashboard .sidebar-toggle-btn .icon-btn {
  background-color: transparent;
  width: 40px;
  margin: 8px 0 8px 20px;
}
.dashboard .sidebar-toggle-btn .icon-btn img {
  max-width: 22px;
}
.dashboard .overlay-sidebar {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 90;
  display: none;
}
@media only screen and (max-width: 87.5em) {
  .dashboard .main-content {
    padding: 3.5rem 5rem;
  }
}
@media only screen and (max-width: 56.25em) {
  .dashboard {
    margin-top: 50px;
  }
  .dashboard .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    -webkit-transform: translateX(-250px);
            transform: translateX(-250px);
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    z-index: 99;
    height: 100vh;
  }
  .dashboard.sidebar-active .overlay-sidebar {
    display: block;
  }
  .dashboard.sidebar-active .sidebar {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  .dashboard.sidebar-active .sidebar .sidebar-container {
    padding-top: 50px;
  }
  .dashboard.sidebar-active .sidebar-toggle-btn {
    background-color: transparent;
  }
}
