@include responsive(phone){
  .xs-hidden{
    display: none;
  }

  .xs-visible{
    display: initial;
  }

  .xs-flex-100{
    width: 100%;
  }

  .xs-flex-col{
    flex-direction: column;
  }

  .xs-flex-wrap{
    flex-wrap: wrap;
  }
  .xs-align-items-center{
    align-items: center;
  }

  .xs-m-a{
    margin: auto;
  }
}
